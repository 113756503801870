import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import { toastAlert } from "../helper/toastAlert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { connection } from "../helper/connection";
import ABI from "../ABI/Abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getuserDetailsAction } from "../helper/multicall";
import config from "../config/config";
import ViewTrans from "../components/viewTrans.js";
import UpgradeLevel from "../components/Upgradelvel.js";
import { checkuser } from "../helper/connection";
import {getHandbook} from "../API/UserActions"
import { Link } from "react-router-dom";
import Choosestandards from "../components/choosestandards.js";
import  ExamplePdf from '../components/user.pdf'
import Sharelink from '../components/Sharelink'
import fileDownload from 'js-file-download'
import axios from "axios"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function DashboardBronze(props) {
  const { t, i18n } = useTranslation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userdata, setuserdata] = useState({});
  const [regbtn, setregbtn] = useState(false);
  const [hash, sethash] = useState(false);
  const [txhash, settxhash] = useState("");
  const [progessbar, setprogessbar] = useState(0);
  const [upgrade, setupgrade] = useState(false);
  const [upgbtn, setupgbtn] = useState(false);
  const [claim, setclaim] = useState(false);
  const [userstatus, setuserstatus] = useState([]);
  const [txhash1, settxhash1] = useState("");
  const [freeze, setfreeze] = useState(false);
  const [handbookdata, sethandbookdata] = useState({"planhandbook":""});
  useEffect(() => {
    AOS.init();
  }, []);
  const handleClaim = async () => {
    try {
      var totalamount = userdata.dailyearned + userdata.earnedamount;
      var claimableamount = 0.01*userdata.planamount;
      if(totalamount<claimableamount){
        toastAlert("error", "Minimum Claimable amount should be equal or more than your daily percentage payout", "invalid");
        return
      }
      setregbtn(true);
      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);
        var currAddr = WEB3.address;

        var getBalance = await web3.eth.getBalance(currAddr);
        var bal = getBalance / 10 ** 18;
        var estimategas = await Contract.methods
          .claimRewards("0")
          .estimateGas({ from: currAddr });
        var gas = estimategas + 100000;
        gas = gas / 10 ** 8;

        console.log(gas, "claaim", bal);
        if (gas > bal) {
          toastAlert("error", "Insufficient Gas fee", "invalid");
          setregbtn(false);
          return;
        }

        var rewardclaim = await Contract.methods
          .claimRewards("0")
          .send({ from: currAddr });
        console.log(rewardclaim, "rewardclaim");
        if (rewardclaim && rewardclaim.status == true) {
          toastAlert("success", "Claim Success", "invalid");
          window.$("#view_trans_modal").modal("show");
          settxhash1(rewardclaim.transactionHash);
          sethash(true);
          setregbtn(false);
          setTimeout(function () { 

                  


            window.location.reload()
          },2000)
        }
      }
    } catch (err) {
      toastAlert("error", "Claim Failed", "invalid");
      setregbtn(false);
    }
  };
  const sharefunction = ()=>{
    window.$("#my_share_modal").modal("show");
  }
const handleRejoin = async ()=>{
  try{
if(progessbar<100){
  toastAlert("error", "2X not reached, Rejoin Failed");
  return
}
if(freeze==false){
  toastAlert("error", "User not claimed, Rejoin Failed");
}
else{
  var WEB3 = await connection();
  if(WEB3 && WEB3.address!=''){
    var contractAddress = config.contractAddress
    var web3 = WEB3.web3;

   

    var Contract = new web3.eth.Contract(ABI,contractAddress);
    var currAddr = WEB3.address;
    var getBalance = await web3.eth.getBalance(currAddr);
    var bal = getBalance / 10 ** 18;
    var plan= await Contract.methods.poolInfo('0').call()
    console.log(plan,'plan')
   
    
    var estimategas = await Contract.methods.reJoinPlan("0",plan.poolPrize).estimateGas({from: currAddr});
    var gas = estimategas+100000;
    gas = gas/10**8;

    console.log(estimategas,'estimategas112')
    if(gas>bal){
      toastAlert('error', "Insufficient Gas fee", 'invalid');  
    
      return
    }
    var rejoinplan = await Contract.methods.reJoinPlan("0",plan.poolPrize).send({from: currAddr});
    console.log(rejoinplan,'rejoinplan')
   if(rejoinplan.status==true){
    setfreeze(false)
    toastAlert('success', "ReJoin success", 'success');
    setTimeout(function () { 

              
      window.location.reload()
    },2000)
   }
  }
}
  }
  catch(err){
console.log(err,'errrrrrrrrr')
toastAlert("error",  "Rejoin Failed")
  }
}
  async function multicall() {
    try {
      let data = { planid: "0" };
      var userprofile = await getuserDetailsAction(data);
      var progress =
        ((userprofile.totalearnedAmount+userprofile.dailyearned) / (userprofile.planamount * 2)) * 100;
      console.log(progress,'pppppppppp');
     
if(userprofile && userprofile.isComplete == true ){
  setfreeze(true)
  var progress =100
  setuserdata({ ...userdata, ...{ totalearnedAmount: userdata.planamount*2 } });
}
else{
  setfreeze(false)
}
      if (
        userprofile &&
        (userprofile.earnedamount <= 0 || userprofile.dailyearned <= 0)
      ) {
        setclaim(true);
      }
      if (
        userprofile &&
        (userprofile.earnedamount >= 0 || userprofile.dailyearned >= 0)
      ) {
        setclaim(false);
      }
      if (
        userprofile &&
        userprofile.totalearnedAmount == userprofile.planamount * 2
      ) {
        setclaim(true);
      } else {
        setclaim(false);
      }
      setprogessbar(progress.toFixed('2'));
      setuserdata(userprofile);
    } catch (err) {
      console.log(err, "eeeeeee");
    }
  }
  useEffect(() => {
    multicall();
  }, [walletConnection.address, txhash1,freeze]);

  useEffect(() => {
    var timer = window.setInterval(async () => {
      try {
        var WEB3 = await connection();
        if (WEB3 && WEB3.address != "") {
          var contractAddress = config.contractAddress;
          var web3 = WEB3.web3;
          var Contract = new web3.eth.Contract(ABI, contractAddress);
          var currAddr = WEB3.address;
          var pid = "0";
          var Roi_income = await Contract.methods
            .checkuptoRoi(currAddr, pid)
            .call();
          console.log(Roi_income, "Roi_income");
          var data = Roi_income / 10 ** 18;

          setuserdata({ ...userdata, ...{ dailyearned: data } });
          if(userdata && userdata.isComplete==false){
            var progress =
            ((userdata.totalearnedAmount+userdata.dailyearned) / (userdata.planamount * 2)) * 100;
              setprogessbar(progress.toFixed('2'));
          }
        
        }
      } catch (err) {
        console.log(err, "er130");
      }
    }, 3000);
    return () => {
      window.clearInterval(timer);
    };
  }, [walletConnection.address, userdata]);
  async function check() {
    var data = { planid: "0" };
    var checkdata = await checkuser(data);
    console.log(checkdata, "checkdata151");
    if (checkdata && !checkdata.exist) {
      window.location.href = "/standard-bronze";
    }
  }

  useEffect(() => {
    check();
  }, [walletConnection.address]);
  async function gethandbook() {
    try{
      var getdata={
        pid:'0'
      }
      var {success,result} = await getHandbook(getdata)
      if(success==true){
        sethandbookdata(result)
      }
    }
    catch(err){

    }
  }
  useEffect(() => {
    gethandbook();
  }, []);
  const handleDownload = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }
  return (
    <div>
      <div className="dash_bg">
        <ScrollToTopOnMount />
        <Navbar />
        <div className="dashboard_boxes">
          <div className="container">
            <Choosestandards/>            
            <h2 className="inner_title mt-5"> My Current Dashboard</h2>
            <div className="dashboard_wrap dashboard_bronze_wrap">            
              <div className="dashboard_wrap_left">
                <Link to="/standard-bronze">
                  <div className="std_box">
                    <h2>{t("BRONZE")}</h2>
                    <div>
                      <h3>
                        {t("LEVEL")} {userdata.level}
                      </h3>
                      <h3>
                        {userdata.refpercent / 100}% {t("DAILY")}
                      </h3>
                    </div>
                  </div>
                </Link>
{console.log(userdata,'userdata194')}
                <div className="dash_box_flex dash_box_flex_custom">
                  <div className="dash_box">
                    <h3>{t("MY_ID")}</h3>
                    <h2>{userdata.userid}</h2>
                  </div>
                  <div className="dash_box">
                    <h3>
                      {t("DIRECT")}
                      <br /> {t("REFERAL")}
                    </h3>
                    <h2>{userdata.referal}</h2>
                  </div>
                </div>

                <div className="dash_box mobile_none">
                  <h3> {t("EARN_MORE")}</h3>
                  <div className="d-flex align-items-center mt-3 mb-3">
                    
                    <CopyToClipboard
                      text={`${config.frontUrl}standard-bronze?referral=` +
                      "EVN" +'BRONZE'+
                      userdata.userid}
                      onCopy={() => {
                        toastAlert("success", "Copied!", "idproof");
                      }}
                    >
                      <h4 style={{"cursor": "pointer"}}>{"EVN" + 'BRONZE'+userdata.userid}</h4>
                    </CopyToClipboard>
                  </div>

                  <div className="d-flex align-items-center">
                  <button className="secondary_btn" onClick={()=>sharefunction()}>
                      {/* <CopyToClipboard
                        text={
                          `${config.frontUrl}standard-bronze?referral=` +
                          "EVN" +
                          userdata.userid+'BRONZE'
                        }
                        onCopy={() => {
                          toastAlert("success", "Copied!", "idproof");
                        }}
                      > */}
                        <span>
                          {" "}
                          <i class="fas fa-share-square me-2"></i> Send Referral Link{" "}
                        </span>
                      {/* </CopyToClipboard> */}
                    </button>
                  </div>
                </div>

                {/* <div className="dash_box dash_box_noheight mobile_none">
                  <div className="dash_btn_grp">
                    <button className="icon_btn">
                      <i className="fab fa-facebook-f"></i>
                    </button>
                    <button className="icon_btn">
                      <i className="fab fa-twitter"></i>
                    </button>
                  </div>
                </div> */}
                {console.log(handbookdata,'handbookdata362',config.serverURL+"handbook/"+handbookdata.planhandbook)}
                <div className="dash_box dash_box_noheight mobile_none">
                    <div className="dash_btn_grp">
                      <a className="secondary_btn" onClick={()=>handleDownload(config.serverURL+"handbook/"+handbookdata.planhandbook,handbookdata.planhandbook)} style={{color:"white","cursor":"pointer"}}>
                         ACCESS TO HANDBOOK
                      </a>
                    </div>
                  </div>
              </div>

              <div className="dashboard_wrap_right">
                <div className="dash_box dash_box_progress">
                  <div className="progress_width_flex">
                    <div className="progress_width">
                      <div class="progress">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-label="Animated striped example"
                          aria-valuenow="75"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${progessbar}%` }}
                        >
                      
                        </div>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="mb-0">{t("PROGRESS")}</p>
                        {/* <p className="mb-0">
                          {progessbar}% / $
                          {userdata &&
                  
                            (userdata.totalearnedAmount+userdata.dailyearned).toFixed("2")}{" "}
                          / <b>${userdata.planamount * 2}</b>
                        </p> */}
                        {userdata.isComplete==false?<p className="mb-0">
                        $
                          {userdata &&
                          (userdata.totalearnedAmount+userdata.dailyearned).toFixed("2")}/<b>${userdata.planamount * 2}</b>                            
                        </p>:<p className="mb-0">
                        <b>${userdata.planamount * 2}</b> /  $
                          {userdata.planamount * 2}{" "}
                        
                        </p>}
                      </div>
                    </div>
                    <p className="text-dark">{progessbar}%</p>
                  </div>
                  {/* <button
                    className="secondary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#upgrade_level_modal"
                  >
                    {" "}
                    {t("UPGRADE")}
                  </button> */}
                  <div >

                  <div
                    className="std_upgrade_div std_upgrade_div_bronze"
                    
                  >
                    <span data-bs-toggle="modal"
                    data-bs-target="#upgrade_level_modal">{t("UPGRADE")}</span>
                    
                 
                   
                    <span  onClick={handleRejoin}>Rejoin</span>
                  </div>
                  
                  </div>
                </div>

                <div className="dash_box_flex dash_box_flex_half_width">
                  <div className="dash_box">
                    <h3>
                      {t("TOTAL")} <br /> {t("INVESTMENT")}
                    </h3>
                    <h2>${userdata.planamount}</h2>
                    <small class="text-dark mt-1">(Your Investment in Bronze Standard)</small>
                  </div>
                  <div className="dash_box">
                    <h3>
                      {t("TOTAL")} <br /> {t("EARNED_INCOME")}
                    </h3>
                    {/* <h2>
                      $
                      
                      {userdata &&
                        userdata.totalearnedAmount &&
                        userdata.totalearnedAmount.toFixed("5")}{" "}
                      / <span>${userdata.planamount * 2}</span>
                    </h2> */}
                    {userdata.isComplete==false ? <h2>
                     
                     $
                     {userdata &&
                      
                      userdata.totalearnedAmount &&
                       userdata.totalearnedAmount.toFixed("5")}{" "}
                     / <span>${userdata.planamount * 2}</span>
                   </h2>:<h2>
                     
                     $
                     {userdata.planamount * 2}{" "}
                     / <span>${userdata.planamount * 2}</span>
                   </h2>}
                    <small className="text-dark mt-1">
                      (Claimed Funds & Referral Income)
                    </small>
                  </div>
                </div>

                <div className="dash_box_flex dash_box_flex_half_width">
                  <div className="dash_box">
                    <h3>
                      {t("EARNED_REFERAL")}
                      <br />
                      {t("INCOME")}
                    </h3>
                    <h2>
                      $
                      {userdata &&
                        userdata.referearn &&
                        userdata.referearn.toFixed("5")}
                    </h2>
                    <small className="text-dark">(Based on Direct Referrals)</small>
                  </div>
                  <div className="dash_box">
                    <h3>                      
                      {t("EARNED_DAILY")} <br /> {t("INCOME")}
                    </h3>                    
                    <h2>
                      $
                      {userdata &&
                        userdata.dailyearned &&
                        userdata.dailyearned.toFixed("5")}
                    </h2>
                    <small className="text-dark">(Based on your chosen Standard & Level)</small>
                  </div>
                </div>

                <div className="dash_box_flex dash_box_flex_half_width justify-content-center">
                  <div className="dash_box dash_box_noheight">
                    <h3>
                      {t("CLAIMABLE")} <br /> {t("FUNDS")}
                    </h3>

                    <h2>
                      $
                      {(userdata.dailyearned + userdata.earnedamount).toFixed(
                        "5"
                      )}
                      {console.log((userdata.dailyearned + userdata.earnedamount),'p2p2p2')}
                     
                    </h2>
                    <button
                      className="secondary_btn"
                      disabled={claim}
                      onClick={() => handleClaim()}
                    >
                      {regbtn && <i class="fas fa-spinner fa-spin"></i>}{" "}
                      {t("CLAIM")}
                    </button>
                    {hash && <a href="view_trans_modal"></a>}
                  </div>
                </div>

                <div className="desktop_none">
                  <div className="dash_box">
                    <h3> {t("EARN_MORE")}</h3>
                    <div className="d-flex align-items-center mt-3 mb-3">
                     
                      <CopyToClipboard
                        text={`${config.frontUrl}standard-bronze?referral=` +
                        "EVN" +'BRONZE'+
                        userdata.userid}
                        onCopy={() => {
                          toastAlert("success", "Copied!", "idproof");
                        }}
                      >
                        <h4 style={{"cursor": "pointer"}}>{"EVN" +'BRONZE'+ userdata.userid}</h4>
                      </CopyToClipboard>
                    </div>

                    <div className="d-flex align-items-center">
                      <button className="secondary_btn" onClick={()=>sharefunction()}>
                        {/* <CopyToClipboard
                          text={
                            `${config.frontUrl}standard-bronze?referral=` +
                            "EVN" +
                            userdata.userid+'BRONZE'
                          }
                          onCopy={() => {
                            toastAlert("success", "Copied!", "idproof");
                          }}
                        >*/}
                          <span>
                            {" "} 
                            <i class="fas fa-share-square me-2"></i>  Send Referral Link{" "}
                          </span>
                        {/* </CopyToClipboard> */}
                      </button>
                    </div>
                  </div>
                  <div className="dash_box dash_box_noheight">
                    <div className="dash_btn_grp">
                      <a   className="secondary_btn"  onClick={()=>handleDownload(config.serverURL+"handbook/"+handbookdata.planhandbook,handbookdata.planhandbook)} style={{color:"white","cursor":"pointer"}}>
                        ACCESS TO HANDBOOK
                      </a>
                    </div>
                  </div>
                  {/* <div className="dash_box dash_box_noheight">
                    <div className="dash_btn_grp">
                      <button className="icon_btn">
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <button className="icon_btn">
                        <i className="fab fa-twitter"></i>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ViewTrans transactionHash={txhash1} />
        <UpgradeLevel settxhash1={settxhash1} txhash1={txhash1} />
        <Sharelink referrallink={`${config.frontUrl}standard-bronze?referral=` +
                      "EVN" +'BRONZE'+
                      userdata.userid}/>
        <Footer />
      </div>
    </div>
  );
}
