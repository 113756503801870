import { React, useState, useEffect } from "react";
import queryString from "query-string";
import { convert } from "../helper/convert";
import { connection } from "../helper/connection";
import { AddressValidations } from "../validation/Addressvalidation";
import { toastAlert } from "../helper/toastAlert";
import { useTranslation } from "react-i18next";
import ABI from "../ABI/Abi.json";
import TokenAbi from "../ABI/TokenAbi.json";
import config from "../config/config";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from "../helper/isEmpty";
const Joinnowmodal = (props) => {
  console.log(props, "vvvvvvvv");
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const [refferal, setrefferal] = useState("");
  const [adminstatus, setadminstatus] = useState(false);
  const [showerror, setshowerror] = useState({});
  const [regbtn, setregbtn] = useState(false);
  const [refstatus, setrefstatus] = useState(false);

  const walletConnection = useSelector((state) => state.walletConnection);
  const handleAddress = (e) => {
    console.log(e.target.value, "vlll");
    setrefferal(e.target.value);
  };
  const handleSubmit = async (id) => {
    try {
      //           var data = {referalAddress:refferal}
      // const errorDatas = await AddressValidations(data)
      if (id == "with") {
        if (isEmpty(refferal)) {
          setshowerror({ referalAddress: "Enter Referral Id" });
          return;
        }
        let result = refferal.includes("EVN");
        if (!result) {
          setshowerror({ referalAddress: "Invalid Referral Id" });

          return;
        }
       
      }

      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        console.log(WEB3, "WEB3");
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);

        var currAddr = WEB3.address;
        if (id == "with") {
          var checkaddress = refferal.replace(/\D/g, "");
          console.log(checkaddress, "checkaddress766");
          if(!checkaddress){
            setshowerror({ referalAddress: "Invalid Referral Id" });

            return;
          }
          var referralcheck = await Contract.methods
            .userIdtoAddress(props.planid, checkaddress)
            .call();
          console.log(referralcheck, "referralcheck62");
          if (referralcheck == "0x0000000000000000000000000000000000000000") {
            setshowerror({ referalAddress: "Invalid Referral Id" });

            return;
          }
        }

        // var chkaddr = web3.utils.isAddress(refferal);
        // if(!chkaddr){
        //   setshowerror({ referalAddress: "Please enter valid address" })

        // }else if(refferal==currAddr){

        //   setshowerror({ referalAddress: "Login address and referer address is should not be same" })
        // }
        // else{
        console.log("elseee49");
        setregbtn(true);
        // setshowerror("");
        var tokenAddress = config.tokenAddress;
        let tokenContract = new web3.eth.Contract(TokenAbi, tokenAddress);
        console.log(tokenContract, "tokenContract");
        var allowance = await tokenContract.methods
          .allowance(currAddr, contractAddress)
          .call();
        console.log(allowance, "allowance");

        var allowamt = allowance / 1000000000000000000;

        var approveAmt = 100000 * 10 ** 18;
        var amt = await convert(approveAmt);
        console.log(allowamt, "amtttttt");
        var plancheck = await Contract.methods
          .userInfo(props.planid, currAddr)
          .call();
        console.log(plancheck.isExist, "pc");
        if (plancheck && plancheck.isExist && plancheck.isExist != false) {
          setregbtn(false);
          toastAlert("error", "Already registered", "invalid");
          setrefferal("");
          window.$("#Standards1").modal("hide");
        } else {
          var plan = await Contract.methods.poolInfo(props.planid).call();
          console.log(plan, "plan");
          var planamount =
            plan && plan.poolPrize ? plan.poolPrize / 1000000000000000000 : 100;
          console.log(planamount, "planamount");
          setregbtn(true);

          var getBalance = await web3.eth.getBalance(currAddr);
          var bal = getBalance / 10 ** 18;
          console.log(bal, "ballll");
          if (bal <= 0) {
            toastAlert("error", "Insufficient Gas fee", "invalid");
            setregbtn(false);
            return;
          }

          let contract = new web3.eth.Contract(TokenAbi, config.tokenAddress);
          let tokenBalance = await contract.methods.balanceOf(currAddr).call();

          const tokenDecimal = await contract.methods.decimals().call();

          var allowablebalance = tokenBalance / 10 ** parseFloat(tokenDecimal);
          console.log(allowablebalance, "allowablebalancewwewww");

          if (allowablebalance < planamount) {
            console.log(allowablebalance, "allowablebalancewwewwwiffffffff");

            toastAlert(
              "error",
              "Failed to Join due to insufficient balance",
              "invalid"
            );
            setregbtn(false);
            return;
          }
          var splitaddress = "";
          if (refferal && refferal.includes("EVN")) {
            //  var splitaddress = refferal.replace('EVN','')
            splitaddress = refferal.replace(/\D/g, "");

            if (allowamt < planamount) {
              var result1 = await tokenContract.methods
                .approve(contractAddress, amt.toString())
                .send({ from: currAddr });
              console.log(result1, "result1");

              if (
                result1 &&
                result1.events &&
                result1.events.Approval &&
                result1.events.Approval.returnValues &&
                result1.events.Approval.returnValues.value
              ) {
                var checkamt =
                  result1.events.Approval.returnValues.value /
                  1000000000000000000;
                if (checkamt < planamount) {
                  toastAlert("error", "Approve amount is too low", "invalid");
                  setregbtn(false);
                  return;
                }
              }
            }

            console.log(splitaddress, "splitaddress");
            var estimategas = await Contract.methods
              .joinPlan(splitaddress, props.planid, plan.poolPrize)
              .estimateGas({ from: currAddr });
            var gas = estimategas + 100000;
            gas = gas / 10 ** 8;

            console.log(estimategas, "estimategas");
            if (gas > bal) {
              toastAlert("error", "Insufficient Gas fee", "invalid");
              setregbtn(false);
              return;
            }

            var joinplan = await Contract.methods
              .joinPlan(splitaddress, props.planid, plan.poolPrize)
              .send({ from: currAddr });

            console.log(joinplan, "joinplan");
            if (joinplan.status == true) {
              setregbtn(false);
              toastAlert("success", "Register success", "success");
              setrefferal("");
              window.$("#Standards1").modal("hide");

              setTimeout(function () {
                var url = window.location.href;
                console.log(url, "uriii");
                var splitup = url.split("?");
                console.log(splitup, "splitup");
                if (splitup && splitup.length > 0) {
                  var redirecturl = splitup[0];
                  window.location.href = redirecturl;
                } else {
                  window.location.reload(false);
                }
              }, 500);
            }
          }
        else {
            var ownerAddress = await Contract.methods.OwnerWallet().call();
            console.log(ownerAddress, "ownerAddress2312");
            var admindata = await Contract.methods
              .userInfo(props.planid, ownerAddress)
              .call();
            console.log(admindata, "admindata53");
            splitaddress = admindata.id;

            //if (window.confirm("Are you sure to continue without referral")) {
              if (allowamt < planamount) {
                var result1 = await tokenContract.methods
                  .approve(contractAddress, amt.toString())
                  .send({ from: currAddr });
                console.log(result1, "result1");

                if (
                  result1 &&
                  result1.events &&
                  result1.events.Approval &&
                  result1.events.Approval.returnValues &&
                  result1.events.Approval.returnValues.value
                ) {
                  var checkamt =
                    result1.events.Approval.returnValues.value /
                    1000000000000000000;
                  if (checkamt < planamount) {
                    toastAlert("error", "Approve amount is too low", "invalid");
                    setregbtn(false);
                    return;
                  }
                }
              }

              console.log(splitaddress, "splitaddress");
              var estimategas = await Contract.methods
                .joinPlan(splitaddress, props.planid, plan.poolPrize)
                .estimateGas({ from: currAddr });
              var gas = estimategas + 100000;
              gas = gas / 10 ** 8;

              console.log(estimategas, "estimategas");
              if (gas > bal) {
                toastAlert("error", "Insufficient Gas fee", "invalid");
                setregbtn(false);
                return;
              }

              var joinplan = await Contract.methods
                .joinPlan(splitaddress, props.planid, plan.poolPrize)
                .send({ from: currAddr });

              console.log(joinplan, "joinplan");
              if (joinplan.status == true) {
                setregbtn(false);
                toastAlert("success", "Register success", "success");
                setrefferal("");
                window.$("#Standards1").modal("hide");

                setTimeout(function () {
                  var url = window.location.href;
                  console.log(url, "uriii");
                  var splitup = url.split("?");
                  console.log(splitup, "splitup");
                  if (splitup && splitup.length > 0) {
                    var redirecturl = splitup[0];
                    window.location.href = redirecturl;
                  } else {
                    window.location.reload(false);
                  }
                }, 500);
              }
            
            // } else {
            //   setregbtn(false);
            //   window.$("#Standards1").modal("hide");
            // }
          }
        }
      } else {
        toastAlert("error", "Please connect your wallet", "invalid");
      }
    } catch (err) {
      setregbtn(false);

      toastAlert("error", "Failed to register", "invalid");

      console.log(err, "errrrrrrrrrrr");
    }
  };

  const handleAdd = async () => {
    setrefstatus(true);
  };

  const handleClose = (e) => {
    if (props && props.address) {
      window.$("#Standards1").modal("hide");
      setshowerror({});
    } else {
      if (props && props.planid == "") {
        setrefferal("");
        setshowerror({});
        window.$("#Standards1").modal("hide");
      }
      setrefstatus(false);
      window.$("#Standards1").modal("hide");
      setshowerror("");
      setrefferal("");
    }
  };

  async function checkadmin() {
    if (props && props.planid) {
      var planval = "BRONZE";
      var id = props.planid;
      if (id == "0") {
        planval = "BRONZE";
      } else if (id == "1") {
        planval = "SILVER";
      } else if (id == "2") {
        planval = "GOLD";
      } else if (id == "3") {
        planval = "PLATINUM";
      } else if (id == "4") {
        planval = "DIAMOND";
      }
      var WEB3 = await connection();
      console.log(WEB3, "WEB3WEB3");
      if (WEB3 && WEB3.address != "") {
        console.log(WEB3, "WEB3WEB32");
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);
        var currAddr = WEB3.address;
        console.log(currAddr, "currAddr");
        var ownerAddress = await Contract.methods.OwnerWallet().call();
        console.log(ownerAddress, "ownerAddress2312");
        var admindata = await Contract.methods
          .userInfo(props.planid, ownerAddress)
          .call();
        console.log(admindata, "admindata");
        if (admindata && admindata.id) {
          setadminstatus(true);
          setrefferal("EVN" + admindata.id + planval);
        }
      }
    }
  }

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    console.log(parsed, "parsed");
    if (parsed && parsed.referral && parsed.referral != "") {
      setrefstatus(true);
    }
    if (props && props.address) {
      setrefferal(props.address);
      localStorage.setItem("refxyz", props.address);
    }
    // else{
    //   checkadmin()
    // }
    if (localStorage && localStorage.getItem("refxyz") != "") {
      setrefferal(localStorage.getItem("refxyz"));
    }
  }, [walletConnection, walletConnection.address, props]);

  return (
    <div
      class="modal fade standard_popup"
      id="Standards1"
      tabindex="-1"
      aria-labelledby="StandardsLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="StandardsLabel">
              {/* {t('STANDARDS')} */}
            </h5>
            <button
              type="button"
              class="btn-close"
              // data-bs-dismiss="modal"
              onClick={handleClose}
              aria-label="Close"
            >
              <img
                src={require("../assets/images/close_icon.png")}
                alt="Image"
                className="img-fluid"
              />
            </button>
          </div>
          <div class="modal-body">
            <div className="joinbox">
              <label className="mb-2">{t("REFERAL_ID")}</label>
              {refstatus == true && (
                <>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      onChange={handleAddress}
                      value={refferal}
                    />
                  </div>
                  <small className="text-end d-block">
                    Example: EVN12345
                    {props && props.planname ? props.planname : ""}
                  </small>

                  {showerror && showerror.referalAddress != "" && (
                    <p style={{ color: "yellow", fontSize: "15px","font-weight": "bolder",
                   "text-transform": "uppercase" }}>
                      {showerror.referalAddress}
                    </p>
                  )}
                </>
              )}
              {/* <label className="mb-2" >Amount</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control"   />
            </div> */}

              <div className="text-center">
                {refstatus == false && (
                  <button
                    class="btn primary_btn mt-3 me-3 p-3 py-2"
                    onClick={() => handleAdd()}
                  >
                    Add
                  </button>
                )}
                {(refstatus == false ||
                  showerror.referalAddress == "Invalid Referral Id") && (
                  <button
                    disabled={regbtn}
                    class="btn primary_btn mt-3 p-3 py-2"
                    onClick={() => handleSubmit("without")}
                  >
                    {regbtn && <i class="fas fa-spinner fa-spin"></i>}Continue
                    without Referral
                  </button>
                )}
                {refstatus == true &&
                  showerror.referalAddress != "Invalid Referral Id" && (
                    <button
                      disabled={regbtn}
                      class="btn primary_btn mt-3 p-3 py-2"
                      onClick={() => handleSubmit("with")}
                    >
                      {regbtn && <i class="fas fa-spinner fa-spin"></i>}Continue
                    </button>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Joinnowmodal;
