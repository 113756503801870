export default{
    "STANDARDS": "选择标准",
    "CONNECT_WALLET": "连接钱包",
    "MY_WALLET": "我的钱包",
    "BRONZE": "青铜",
    "SILVER": "银",
    "GOLD": "金子",
    "PLATINUM": "铂",
    "DIAMOND": "钻石",
    "METAMASK": "metamask",
    "WALLET_CONNECT": "钱包连接",
    "HOME_TITLE": "精英视觉网络",
    "TITLE_DESC": "控制时间的力量增加您的投资",
    "LEARN_MORE": "了解更多",
    "EARN": "每天赚取1.5％的投资",
    "CONTENT_1": "它不仅幸存了五个世纪，而且还跨越了电子排版，基本上保持不变。它在1960年代被释放，随着包含lorem ipsum段落的letraset板的发布。",
    "CONTENT_2": "它不仅幸存了五个世纪，而且还跨越了电子排版，基本上保持不变。它在1960年代被释放，随着包含lorem ipsum段落的letraset板的发布。",
    "INTL_COMMUNITY": "国际社会",
    "GLOBAL": "全球业务",
    "RISK": "无风险投资",
    "RISK_DESC": "将您的最小投资放在最大可能的收入中。精英视觉网络是一个无风险的网络门户网站，可让您在全球社区中赚很多钱。",
    "SECURE": "安全区块链",
    "SECURE_DESC": "Elite Vision Network在BSC（Binance Smart Chain）上运行，这是最有害的区块链。它是完全分散的，为您提供了可靠的数据和投资安全性。",
    "RESTRICT": "无限制进入",
    "RESTRICT_DESC": "登录精英视觉网络没有限制。您可以通过以50美元（最低）投资进入平台来轻松开始赚取很多收入。",
    "MEMBER": "会员可以通过简单的推荐来赚取巨额资金。转介数是无限的。您可以投资精英视觉网络从最低$ 50到最高$ 5,00,000。使用Elite Vision Network，您可以找到4种不同类型的收入尖叫：推荐收入；回报率；水平收入；和全球池收入 - 与7个级别有关。最低撤回$ 10。",
    "EXPLAIN": "收入的解释",
    "EXPLAIN_TITLE": "lorem ipsum只是打印的虚拟文本",
    "EXPLAIN_DESC": "它不仅幸存了五个世纪，而且还幸存下来，而且飞向电子排版，基本上保持不变。它在1960年代被释放，其中包含Lorem Ipsum段落。",
    "EXPLAIN_DESC_1": "它不仅幸存了五个世纪，而且还幸存下来，而且飞向电子排版，基本上保持不变。它在1960年代被释放，其中包含Lorem Ipsum段落。它不仅幸存了五个世纪，而且还幸存下来，而且飞向电子排版，基本上保持不变。它在1960年代被释放，其中包含Lorem Ipsum段落。",
    "SMART_CONTRACT": "智能合同地址",
    "FIXED_INVEST": "固定投资",
    "REFERAL_INCOME": "推荐收入",
    "LEVEL_BASED": "基于水平的兴趣",
    "LEVEL_ZERO": "L0-零推荐）=每天1.5％",
    "BRONZE_L1": "L1-至少1个直接推荐=每天1.8％",
    "BRONZE_L2": "L2-（至少3个直接推荐）=每天2％",
    "BRONZE_L3": "L3-（至少5个直接推荐）=每天2.2％",
    "BRONZE_L4": "L4-（至少8个直接推荐）=每天2.5％",
    "SILVER_L1": "L1-（至少2个直接推荐）=每天1.8％",
    "SILVER_L2": "L2-（至少5个直接推荐）=每天2％",
    "SILVER_L3": "L3-（至少7个直接推荐）=每天2.2％",
    "SILVER_L4": "L4-（至少10个直接推荐）=每天3％",
    "GOLD_LEVEL0": "L0-（零推荐）=每天1％",
    "GOLD_L1": "L1-（至少3个直接推荐）=每天2％",
    "GOLD_L2": "L2-（至少7个直接推荐）=每天2.5％",
    "GOLD_L3": "L3-（至少9个直接推荐）=每天3％",
    "GOLD_L4": "L4-（至少10个直接推荐）=每天3.5％",
    "PLATINUM_LEVEL0": "L0-（零推荐）=每天1％",
    "PLATINUM_L1": "L1-（至少3个直接推荐）=每天2.5％",
    "PLATINUM_L2": "L2-（至少7个直接推荐）=每天3％",
    "PLATINUM_L3": "L3-（至少10个直接推荐）=每天3.5％",
    "PLATINUM_L4": "L4-（至少12个直接推荐）=每天4％",
    "DIAMOND_LEVEL0": "L0-（零推荐）=每天0.5％",
    "DIAMOND_L1": "L1-（至少2个直接推荐）=每天2.5％",
    "DIAMOND_L2": "L2-（至少5个直接推荐）=每天3％",
    "DIAMOND_L3": "L3-（至少7个直接推荐）=每天3.5％",
    "DIAMOND_L4": "L4-（至少10个直接推荐）=每天4.5％",
    "DIRECT": "直接的",
    "REFERAL": "推荐",
    "TOTAL": "全部的",
    "INVESTMENT": "投资",
    "EARNED_INCOME": "赚取收入",
    "EARNED_REFERAL": "赢得了推荐",
    "INCOME": "收入",
    "EARNED_DAILY": "每天赚钱",
    "CLAIM": "宣称",
    "CLAIMABLE": "可声称",
    "FUNDS": "资金",
    "UPGRADE": "升级",
    "MY_ID": "我的身份",
    "EARN_MORE": "分享推荐",
    "PER_REFERAL":"每个推荐",
    "DAILY":"日常的",
    "UP_TO":"取决于",
    "LEVEL":"等级",
    "PROGRESS":"收入达到总投资 2 倍的进展",
    "COPYRIGHT":"版权所有",
    "ALL_RIGHTS":"版权",
    "ADDRESS":"地址",
    "BALANCE":"平衡",
    "DISCONNECT":"断开",
    "JOIN_NOW":"立即加入",
    "REFERAL_ID":"重新赋予ID",
    "SUBMIT":"提交",
    "RECENT_ACTIVITY":"平台最近的活动"
}