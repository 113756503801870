export default{
    "STANDARDS": "표준을 선택하십시오",
    "CONNECT_WALLET": "지갑을 연결하십시오",
    "MY_WALLET": "내 지갑",
    "BRONZE": "청동",
    "SILVER": "은",
    "GOLD": "금",
    "PLATINUM": "백금",
    "DIAMOND": "다이아몬드",
    "METAMASK": "메타 마스크",
    "WALLET_CONNECT": "지갑 연결",
    "HOME_TITLE": "엘리트 비전 네트워크",
    "TITLE_DESC": "투자를 두 배로 늘리기위한 시간의 힘을 통제하십시오",
    "LEARN_MORE": "더 알아보기",
    "EARN": "매일 투자로 1.5%를 벌 수 있습니다",
    "CONTENT_1": "그것은 5 세기뿐만 아니라 전자 조판으로 도약했으며, 본질적으로 변경되지 않은 상태로 남아 있었으며, 1960 년대 Lorem Ipsum 구절을 포함하는 Letraset 시트가 출시되면서 대중화되었습니다.",
    "CONTENT_2": "그것은 5 세기뿐만 아니라 전자 조판으로 도약했으며, 본질적으로 변경되지 않은 상태로 남아 있었으며, 1960 년대 Lorem Ipsum 구절을 포함하는 Letraset 시트가 출시되면서 대중화되었습니다.",
    "INTL_COMMUNITY": "국제 공동체",
    "GLOBAL": "글로벌 비즈니스",
    "RISK": "무위험 투자",
    "RISK_DESC": "최소 투자를 가능한 최대 소득에 투자하십시오. 엘리트 비전 네트워크는 전 세계 커뮤니티와 많은 돈을 벌 수있는 위험이없는 네트워킹 포털입니다.",
    "SECURE": "보안 블록 체인",
    "SECURE_DESC": "엘리트 비전 네트워크는 가장 안전한 블록 체인 인 BSC (Binance Smart Chain)에서 실행됩니다. 완전히 분산되어 있으며 데이터 및 투자에 대한 강력한 보안을 제공합니다.",
    "RESTRICT": "제한이없는 입력",
    "RESTRICT_DESC": "엘리트 비전 네트워크에 로그온하는 데 제한이 없습니다. $ 50 (최소) 투자로 플랫폼에 들어가서 쉽게 많은 돈을 벌기 시작할 수 있습니다.",
    "MEMBER": "회원은 쉬운 추천을 통해 막대한 돈을 벌 수 있습니다. 추천 카운트는 무제한입니다. 최소 $ 50에서 최대 $ 5,00,000까지 엘리트 비전 네트워크에 투자 할 수 있습니다. Elite Vision Network를 사용하면 4 가지 유형의 소득 비명을 찾을 수 있습니다. 추천 소득; 수익률; 레벨 소득; 및 전 세계 수영장 소득 - 7 레벨과 관련된. 최소 $ 10의 인출.",
    "EXPLAIN": "소득에 대한 설명",
    "EXPLAIN_TITLE": "Lorem Ipsum은 단순히 인쇄의 더미 텍스트입니다",
    "EXPLAIN_DESC": "그것은 5 세기뿐만 아니라 전자 조판으로의 도약으로 살아 남았으며, 본질적으로 변하지 않았다. Lorem Ipsum 구절이 포함 된 Letraset 시트의 출시로 1960 년대에 대중화되었습니다.",
    "EXPLAIN_DESC_1": "그것은 5 세기뿐만 아니라 전자 조판으로의 도약으로 살아 남았으며, 본질적으로 변하지 않았다. Lorem Ipsum 구절이 포함 된 Letraset 시트의 출시로 1960 년대에 대중화되었습니다. 그것은 5 세기뿐만 아니라 전자 조판으로의 도약으로 살아 남았으며, 본질적으로 변하지 않았다. Lorem Ipsum 구절이 포함 된 Letraset 시트의 출시로 1960 년대에 대중화되었습니다.",
    "SMART_CONTRACT": "스마트 계약 주소",
    "FIXED_INVEST": "고정 투자",
    "REFERAL_INCOME": "추천 소득",
    "LEVEL_BASED": "레벨 기반 관심사",
    "LEVEL_ZERO": "l0- (제로 추천) = 매일 1.5%",
    "BRONZE_L1": "L1- (최소 1 직접 추천) = 1.8% 매일",
    "BRONZE_L2": "l2- (최소 3 개의 직접 진료 의뢰) = 매일 2%",
    "BRONZE_L3": "l3- (최소 5 개의 직접 진료 의뢰) = 매일 2.2%",
    "BRONZE_L4": "l4- (최소 8 개의 직접 진료 의뢰) = 매일 2.5%",
    "SILVER_L1": "L1- (최소 2 직접 추천) = 1.8% 매일",
    "SILVER_L2": "L2- (최소 5 개의 직접 추천) = 매일 2%",
    "SILVER_L3": "L3- (최소 7 개의 직접 진료 의뢰) = 매일 2.2%",
    "SILVER_L4": "l4- (최소 10 개의 직접 진료 의뢰) = 매일 3%",
    "GOLD_LEVEL0": "l0- (제로 추천) = 매일 1%",
    "GOLD_L1": "L1- (최소 3 직접 추천) = 매일 2%",
    "GOLD_L2": "L2- (최소 7 개의 직접 진료 의뢰) = 매일 2.5%",
    "GOLD_L3": "l3- (최소 9 개의 직접 진료 의뢰) = 매일 3%",
    "GOLD_L4": "l4- (최소 10 개의 직접 진료 의뢰) = 매일 3.5%",
    "PLATINUM_LEVEL0": "l0- (제로 추천) = 매일 1%",
    "PLATINUM_L1": "L1- (최소 3 직접 추천) = 매일 2.5%",
    "PLATINUM_L2": "L2- (최소 7 개의 직접 추천) = 매일 3%",
    "PLATINUM_L3": "L3- (최소 10 개의 직접 진료 의뢰) = 매일 3.5%",
    "PLATINUM_L4": "l4- (최소 12 개의 직접 진료 의뢰) = 매일 4%",
    "DIAMOND_LEVEL0": "l0- (제로 추천) = 매일 0.5%",
    "DIAMOND_L1": "L1- (최소 2 직접 추천) = 매일 2.5%",
    "DIAMOND_L2": "L2- (최소 5 개의 직접 추천) = 매일 3%",
    "DIAMOND_L3": "L3- (최소 7 개의 직접 진료 의뢰) = 매일 3.5%",
    "DIAMOND_L4": "L4- (최소 10 개의 직접 진료 의뢰) = 4.5% 매일",
    "DIRECT": "직접",
    "REFERAL": "추천",
    "TOTAL": "총",
    "INVESTMENT": "투자",
    "EARNED_INCOME": "소득 소득",
    "EARNED_REFERAL": "획득 된 추천",
    "INCOME": "소득",
    "EARNED_DAILY": "매일 획득",
    "CLAIM": "주장하다",
    "CLAIMABLE": "청구 가능",
    "FUNDS": "자금",
    "UPGRADE": "업그레이드",
    "MY_ID": "내 ID",
    "EARN_MORE": "공유 추천",
    "PER_REFERAL":"추천 당",
    "DAILY":"일일",
    "UP_TO":"까지",
    "LEVEL":"수준",
    "PROGRESS":"총 투자의 2배에 도달하는 근로 소득을 향한 진전",
    "COPYRIGHT":"저작권",
    "ALL_RIGHTS":"판권 소유",
    "ADDRESS":"주소",
    "BALANCE":"균형",
    "DISCONNECT":"연결을 끊습니다",
    "JOIN_NOW":"지금 가입하세요",
    "REFERAL_ID":"된 추천 ID",
    "SUBMIT":"제출하다",
    "RECENT_ACTIVITY":"플랫폼 최근 활동"
}