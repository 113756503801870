import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";

import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import StandardBronze from "./pages/standard-bronze.js";
import StandardSilver from "./pages/standard-silver.js";
import StandardGold from "./pages/standard-gold.js";
import StandardPlatinum from "./pages/standard-platinum.js";
import StandardDiamond from "./pages/standard-diamond.js";
import DashboardBronze from './pages/dashboard-bronze.js';
import DashboardSilver from './pages/dashboard-silver.js';
import DashboardGold from './pages/dashboard-gold.js';
import DashboardPlatinum from './pages/dashboard-platinum.js';
import DashboardDiamond from './pages/dashboard-diamond.js';
import Lock from "./pages/lock.js"
import { ToastContainer } from "react-toastify";





import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { bsc, bscTestnet, polygon, polygonMumbai } from 'viem/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [publicProvider()],
)

console.log(chains,'chains399')
// Set up wagmi config
const config = createConfig({

  autoConnect: true,
  connectors: [


    new MetaMaskConnector({ chains ,
      shimDisconnect: true,
    }),
    // new CoinbaseWalletConnector({
    //   chains,
    //   options: {
    //     appName: 'Web3modalv2',
    //     jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/N55E4OBB31jjeOw9d6FBUVebdYu3chSY',
    //   },
    // }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})


const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "/dashboard", element: <Dashboard /> },
    { path: "/standard-bronze", element: <StandardBronze /> },
    { path: "/standard-silver", element: <StandardSilver /> },
    { path: "/standard-gold", element: <StandardGold /> },
    { path: "/standard-platinum", element: <StandardPlatinum /> },
    { path: "/standard-diamond", element: <StandardDiamond /> },
    { path: "/dashboard-bronze", element: <DashboardBronze /> },
    { path: "/dashboard-silver", element: <DashboardSilver /> },
    { path: "/dashboard-gold", element: <DashboardGold /> },
    { path: "/dashboard-platinum", element: <DashboardPlatinum /> },
    { path: "/dashboard-diamond", element: <DashboardDiamond /> },
    // { path: "/evnytfyfyfgjhghyurd5y", element: <Lock /> },
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
     <WagmiConfig config={config}>
          <ToastContainer />
          <App />
        </WagmiConfig>
    </Router>
  );
};

export default AppWrapper;