
import {React,useState} from 'react'
import config from "../config/config"
const ViewTrans = (props) => {


 
  
        const handleClose = (e) => {
          
          window.$('#view_trans_modal').modal('hide');
        }
  return (
    <div
    className="modal fade standard_popup "
    id="view_trans_modal"
    tabIndex={-1}
    aria-labelledby="view_trans_modal"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
        <h5 className="modal-title" id="connect_walletModalLabel">
           Transaction Completed
          </h5>
          <button
            type="button"
            class="btn-close"
            // data-bs-dismiss="modal"
            onClick={ handleClose} 
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        <div className="modal-body">
          <div>
            <div className="m-2 mb-3" >
            <div class="form-group">
    
  <a href={`${config.txUrl}${props.transactionHash}`} style={{"textAlign":"center !important"}}target="_blank">View Transaction</a>
  </div>
          
            
     
        
         
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ViewTrans;