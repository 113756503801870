// import constant
import {
    SET_LANG
} from '../constant';

const initialValue = {
    isAuthenticated: false
}
const setLang = (state = initialValue, action) => {
    switch (action.type) {
        case SET_LANG:
            return {
                ...action.data
            };
        default:
            return state;
    }
}

export default setLang;