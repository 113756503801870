
import {React,useState,useEffect} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Web3Modal from "web3modal";
import { useSelector,useDispatch } from 'react-redux';
import { setWallet } from "../reducers/Actions";
import { providerOptions } from "../config/providerOptions"
import { toastAlert } from "../helper/toastAlert";
import { useTranslation } from "react-i18next";

import {toFixedFormat } from "../helper/custommath"
import TokenAbi from "../ABI/TokenAbi.json"
import config from "../config/config"
import { type PublicClient, type WalletClient, useWalletClient, usePublicClient, useDisconnect, useNetwork ,useSwitchNetwork} from 'wagmi'
const Mywallet = (props) => {
console.log(props,'props')
const { chain } = useNetwork()
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const [balance, setbalance] = useState("");
    const [tokenbalance, settokenbalance] = useState("");
    const walletConnection = useSelector((state) => state.walletConnection);


    const { disconnect,isSuccess,status } = useDisconnect()
    console.log(walletConnection,'walletConnection17')
    if(isSuccess == true){
      
      console.log("hhhhhhhhhhhhh")
      window.$('#my_wallet_modal').modal('hide');
      setTimeout(function () {
                window.location.reload(false)
              }, 500);
    }
    console.log(chain , config.NetworkId,'oneeeeeeeeeeee')
    async function getuserBalance() {
   
      if (chain && chain.id == config.NetworkId){

      
      if (walletConnection && walletConnection.web3 && walletConnection.address && walletConnection.address != "") {
      
        var web3 = walletConnection.web3;
        var getBalance = await web3.eth.getBalance(walletConnection.address);
        var bal = getBalance / 10 ** 18;
        bal = await toFixedFormat(bal);
        setbalance(bal);
  
                let contract = new web3.eth.Contract(TokenAbi,config.tokenAddress);
                let tokenBalance = await contract.methods.balanceOf(walletConnection.address).call();
                console.log(tokenBalance,'tokenBalance112')
                const tokenDecimal = await contract.methods.decimals().call();
              
                var allowablebalance = tokenBalance/(10 ** parseFloat(tokenDecimal))
             var tbal = allowablebalance.toFixed(8)
                settokenbalance(tbal)
      }
    }
    if(chain && chain.id != config.NetworkId){
      toastAlert("error", "Choose Bsc Network",'err') 
      setTimeout(function () {
        disconnect()
              }, 1000);
        
  
    }
    }
    // const disconnectWeb3Wallet = async () => {
    //     console.log("disconnectWeb3Wallet")
    //     try {
    
    //       const web3Modal = new Web3Modal({
    //         providerOptions, // required,
    //         cacheProvider: false, // optional
    //       });
    
    //       dispatch(setWallet({
    //         network: "",
    //         web3: "",
    //         address: "",
    //         provider: "", provider: ""
    
    //       }));
    //       localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    //       localStorage.removeItem("connect");
    //       localStorage.removeItem("iswallet");
    //       var cookies = document.cookie.split(";");
    
    //       for (var i = 0; i < cookies.length; i++) {
    //         var cookie = cookies[i];
    //         var eqPos = cookie.indexOf("=");
    //         var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //         document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    //       }
    
    //       await web3Modal.clearCachedProvider();
    //       window.$('#my_wallet_modal').modal('hide');
    //       setTimeout(function () {
    //         window.location.reload(false)
    //       }, 500);
    
    //     }
    //      catch (error) {
    //       console.error(error);
    //     }
    //   };
   
   
   
    const disconnectWeb3Wallet = async () => {
      try {
  
        dispatch(setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true"
        }));
        localStorage.clear();
  
        var cookies = document.cookie.split(";");
  
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
  
  
        setTimeout(function () {
          window.location.reload(false)
        }, 1000);
  
      } catch (error) {
        console.error(error, 'rerereeeeeee');
      }
    }
    useEffect(() => {
      getuserBalance()

    },[walletConnection , walletConnection.network])
  return (
    <div
    className="modal fade standard_popup walletpopup"
    id="my_wallet_modal"
    tabIndex={-1}
    aria-labelledby="connect_walletModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="connect_walletModalLabel">
          {t('MY_WALLET')}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        <div className="modal-body">
          <div>
            <div className="m-2 mb-3" >
       
          

              <p><span className='clr' > {t('ADDRESS')}:</span><span>{walletConnection &&  walletConnection.address ? walletConnection.address.slice(0,14)+'....':''}
              <CopyToClipboard text={walletConnection &&  walletConnection.address}
                onCopy={() => { toastAlert("success", "Copied!",'idproof') }}>
                   <a href="javascript:void(0)">
                      <i class="fas fa-copy ms-2"></i>
                        </a>
                        </CopyToClipboard></span></p>              
              <p> <span className='clr' >USDT Balance:</span><span>{tokenbalance? tokenbalance:0}</span></p>
              <p> <span className='clr' >BNB Balance:</span><span>{ balance? balance:0}</span></p>
              <button class="btn primary_btn p-3 py-2" onClick={() => disconnect()}>{t('DISCONNECT')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Mywallet;