import {
    SET_WEB3,
    WALLET_CONNECT,
    SET_LANG
} from '../constant';

export const setWeb3 = details => {
    return {
        type: SET_WEB3,
        payload: details
    };
};

export const setWallet = details => {
  
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};
export const selectLang = (data) => {
    console.log(data,'data22')
    return {
        type: SET_LANG,
        data
    }
}