import config from "../config/config"
import Abi from "../ABI/Abi.json"
import moment from 'moment';
import Web3 from "web3";
import syncEach from 'sync-each'
import axios from 'axios'
const abiDecoder = require('abi-decoder');
abiDecoder.addABI(Abi);
export const getlasttransaction = async (setuserdata) => {
    try {
        const web3_bsc = new Web3(config.netWorkUrl)
        const BscContract = new web3_bsc.eth.Contract(Abi, config.contractAddress);
      
            var res = await axios.post('https://api.bscscan.com/api?module=account&action=txlist&address=' + config.contractAddress + '&page=1&offset=5&sort=desc&apikey=I6WH378GVYIUI5INBH2RUP9BSXEYU7N88Q')
           console.log(res,'ressssssssss')
             let arrrec = [];
            if (res.data && res.data.result && (res.data.result).length > 0) {
                syncEach((res.data.result), async function (items, next) {
                  console.log(items,'items')
              let trans_hash = await web3_bsc.eth.getTransaction(items.hash);
            console.log('trans_hashhhhhhhhhhh: ', trans_hash);
                    if (trans_hash && trans_hash.input) {
                        let trans_detail = abiDecoder.decodeMethod(trans_hash.input);
                        // let userdet = await BscContract.methods.userInfo('1',items.from).call();
                        let date = moment.unix(items.timeStamp).toDate();
                        var now = moment(new Date()); //todays date
                        var end = moment(date); // another date
                        var duration = now.diff(end, "minutes");
                        var duration1 = now.diff(end, "hours");
                       
                        if (trans_detail && trans_detail.name && trans_detail.name == "joinPlan") {
                            console.log(trans_detail,'trans_detail')
                            let datas = {
                               
                                "userid": trans_hash.from,
                                "trx": items.hash,
                                "min": duration,
                                "hours": duration1,
                                "type":"joinPlan"

                            }
                            console.log(datas,'datas')
                            arrrec.push(datas);
                            next();
                        }
                        else if(trans_detail && trans_detail.name && trans_detail.name == "claimRewards") {
                            console.log(trans_detail,'claimRewards')
                            let datas = {
                               
                                "userid": trans_hash.from,
                                "trx": items.hash,
                                "min": duration,
                                "hours": duration1,
                                "type":"claimRewards"

                            }
                           
                            arrrec.push(datas);
                            next();
                        }
                        else if(trans_detail && trans_detail.name && trans_detail.name == "upgradePlan") {
                            console.log(trans_detail,'upgradePlan')
                            let datas = {
                               
                                "userid": trans_hash.from,
                                "trx": items.hash,
                                "min": duration,
                                "hours": duration1,
                                "type":"upgradePlan"

                            }
                           
                            arrrec.push(datas);
                            next();
                        }
                        else {
                            next();
                        }
                    } else {
                        next();
                    }
                }, function (err, transformedItems) {
                  
                    setuserdata(arrrec)
                    // resp.status(200).json({ 'message': 'Success', data: arrrec })
                })
            
        };
    } catch (errs) {
        console.log(errs, "======errororororororororor")
    }
}