import { connection } from "./connection";
import { Multicall } from "ethereum-multicall"
import config from "../config/config";
import Abi from '../ABI/Abi.json'
import { bignumber } from '../helper/custommath';
export const getuserDetailsAction = async (data) => {
    try {
        console.log(data,'userssssssssssssss')
        var get = await connection();
        var planid = data.planid
        console.log(get,'getget')
        if (get && get.web3 && get.address && (get.network == config.NetworkId)) {
            let address = get.address;
            console.log(address,'address')
            let web3 = get.web3;
            const multicall = new Multicall({
                web3Instance: web3,
            })

            var contractCallContext = [
                {
                    reference: "details",
                    contractAddress: config.contractAddress,
                    abi: Abi,
                    calls: [
                        {
                            reference: "userprofile",
                            methodName: "userInfo",
                            methodParameters: [planid,address],
                        },
                        {
                            reference: "planinfo",
                            methodName: "poolInfo",
                            methodParameters: [planid],
                        },
                        {
                            reference: "dailyincome",
                            methodName: "checkuptoRoi",
                            methodParameters: [address,planid],
                        },
                        
                        {
                            reference: "referralCount",
                            methodName: "referralCountByUser",
                            methodParameters: [planid,address],
                        },
                        {
                            reference: "referralInfo",
                            methodName: "referralPoolInfo",
                            methodParameters: [planid],
                        },
                    ],
                  
                      
                        
                    
                },
            ];

            const results = await multicall.call(contractCallContext)

            var users = await getFormatMulticall(results, "userprofile", 0);
            var poolInfo= await getFormatMulticall(results, "planinfo", 0);
            var dailyincome= await getFormatMulticall(results, "dailyincome", 0);
            var referalcount= await getFormatMulticall(results, "referralCount", 0);
            var referalinfo= await getFormatMulticall(results, "referralInfo", 0);
           
          
console.log(users,'referalinfousers')
            var isexist = users[0];
            var isFreeze = users[1];
            var isComplete = users[2];
            var userid = await bignumber(users[3]);
            var dailyearned = await bignumber(dailyincome[0]);
            
            dailyearned = parseFloat(dailyearned) / 10 ** 18;
            var planamount = await bignumber(poolInfo[0]);
            console.log(planamount,'planamount')
            planamount = parseFloat(planamount) / 10 ** 18;


            var earnedamount = await bignumber(users[5])
            earnedamount= parseFloat(earnedamount) / 10 ** 18;

           var totalearnedAmount=await bignumber(users[8])
           totalearnedAmount= parseFloat(totalearnedAmount) / 10 ** 18;

            var referearn = await bignumber(users[7])
            referearn= parseFloat(referearn) / 10 ** 18;

            var referal= await bignumber(referalcount[0]);

        
        console.log(planamount,'planamount')
            var reffarr=[]
            var percentarr=[]
            for (var i in referalinfo[0]){
               var pushval= await bignumber(referalinfo[0][i]);
               reffarr.push(pushval)
            }
            for (var i in referalinfo[1]){
                var pushval2=await bignumber(referalinfo[1][i]);
                percentarr.push(pushval2)
            }
            console.log(reffarr,'reffarr')
            console.log(percentarr,'percentarr')
            console.log(referal,'referal')
            var level=0
            var refpercent=percentarr[0]
            if (
                referal>= parseFloat(reffarr[0]) &&
                referal < parseFloat(reffarr[1])
            ) {
                level = 0;
                refpercent=percentarr[0]
            }
            else if(
                referal>= parseFloat(reffarr[1]) &&
                referal < parseFloat(reffarr[2])
            ) {
                level = 1;
                refpercent=parseFloat(percentarr[1])
            }

            else if (
               referal >= parseFloat(reffarr[2]) &&
               referal < parseFloat(reffarr[3])
            ) {
                level = 2;
                refpercent=percentarr[2]
            } else if (
               referal >= parseFloat(reffarr[3])&&
               referal < parseFloat(reffarr[4])
            ) {
                level = 3;
                refpercent=percentarr[3]
            } else if (referal >= parseFloat(reffarr[4])) {
                level = 4;
                refpercent=percentarr[4]
            }
           console.log(level,refpercent,'refpercentrefpercent')
            return {
                status: true,
                isexist:isexist,
                isComplete:isComplete,
                isFreeze:isFreeze,
                userid:userid,
                dailyearned:dailyearned,
                planamount:planamount,
                earnedamount:earnedamount,
                totalearnedAmount:totalearnedAmount,
                referearn:referearn,
                referal:referal,
                level:level,
                refpercent:refpercent
               
            }
        }
        else {
            return {
                status: false,
                isFreeze:false,
                isComplete:false,
                isexist:0,
                userid:0,
                dailyearned:0,
                planamount:0,
                earnedamount:0,
                totalearnedAmount:0,
                referearn:0,
                referal:0
            }
        }
    }
    catch (err) {
        console.log(err,'eeeeerere')
        return {
            status: false,
            
            isexist:0,
            userid:0,
            dailyearned:0,
            planamount:0,
            earnedamount:0,
            referearn:0,
            referal:0
        }
    }
}
export function getFormatMulticall(results, name, pos) {
    try {
        var index = results && results.results && results.results.details &&
            results.results.details.callsReturnContext &&
            results.results.details.callsReturnContext.findIndex(val => val.reference == name);
        var returnVal = results.results.details.callsReturnContext[index] &&
            results.results.details.callsReturnContext[index].returnValues ?
            results.results.details.callsReturnContext[index].returnValues : "";
        return returnVal;
    } catch (err) {
        console.log(err, "==+++++++")
        return "";
    }
}