

import {React,useState,useEffect} from 'react'

import {convert}from "../helper/convert"
import {connection} from "../helper/connection"
import ABI from "../ABI/Abi.json"
import { toastAlert } from "../helper/toastAlert";
import { useTranslation } from "react-i18next";
import {PoolValidations} from "../validation/poolvalidation"
import config from'../config/config'
const Addplanmodal = (props) => {
  var initialvalue = {
    pid: '0',
    poolprice: '',
    referpercent:'',
    referalcountlimit:[],
    percentageLimit:[]
   
}
  const { t, i18n } = useTranslation();

  const [refferal, setrefferal] = useState("");
  const [showerror, setshowerror]= useState({});
  const [regbtn, setregbtn]= useState(false);
  
  const [pooldata, setpooldata] = useState(initialvalue);
      const handleSubmit = async () => {
        try{
          
          const errorDatas = await PoolValidations(pooldata)
          console.log(errorDatas,'errorDatas')
if (Object.keys(errorDatas).length!=0) {
return setshowerror(errorDatas)
}
else{
  console.log('WEB3WEB337')
  var WEB3 = await connection();
  console.log(WEB3,'WEB3WEB337')
  if(WEB3 && WEB3.address!=''){
   
    var contractAddress = config.contractAddress
    var web3 = WEB3.web3;
    var Contract = new web3.eth.Contract(ABI,contractAddress);
    var currAddr = WEB3.address;
    console.log(contractAddress,'contractAddress')
    var poolInfo = await Contract.methods.checkPoolExist(pooldata.pid).call();
    console.log(poolInfo,'poolInfo')  
if(poolInfo == false){
  var ownerAddress = await Contract.methods.OwnerWallet().call();
    console.log(ownerAddress,'ownerAddress')
  
  

    if(ownerAddress == currAddr){
    var addpool = await Contract.methods.addPool(pooldata.pid,pooldata.poolprice,pooldata.referpercent,pooldata.referalcountlimit,pooldata.percentageLimit).send({from: currAddr});
    setpooldata(initialvalue)
    window.$('#addplan').modal('hide');
    toastAlert('success', "Plan Added successfully", 'success');  

     
  }
    else{
      toastAlert('error', "Invalid Admin Address", 'invalid');  
      setregbtn(false)
    }
}
else{
  setpooldata(initialvalue)
  window.$('#addplan').modal('hide');
  toastAlert('error', "Plan Already Exist", 'invalid');  
}
  
  }
}     
        }
        catch(err){
          console.log(err,'eee533')
          setregbtn(false)
          toastAlert('error', "Failed to register", 'invalid');
        }
      }
      const handleClose = (e) => {
        if(props && props.address){
          window.$('#addplan').modal('hide');
          setshowerror({})
        }
        else{
          setrefferal("")
          setshowerror({})
          window.$('#addplan').modal('hide');
        }
        
      }
      const handleChange = (event) => {
        if(event.target.id=='referalcountlimit' || event.target.id=='percentageLimit' ){
          var split=event.target.value.split(',')
          var newData = {
            [event.target.id]: split,
        }
        }
        else{
          var newData = {
            [event.target.id]: event.target.value,
        }
       }
  
        console.log(newData,'newData')
        setpooldata({ ...pooldata, ...newData })
    }

  
  return (
   
    <div
    class="modal fade standard_popup"
    id="addplan"
    tabindex="-1"
    aria-labelledby="StandardsLabel"
    aria-hidden="true"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="StandardsLabel">
          Add Plan
          </h5>
          <button
            type="button"
            class="btn-close"
            // data-bs-dismiss="modal"
            onClick={ handleClose} 
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        { console.log(pooldata,'setpooldata')}
        <div class="modal-body">
          <div className="joinbox" >
            <div>
          <label>Select plan</label>
          <select
                  class="form-select plan_select"
                  aria-label="Default select example"
                  id="pid"
                  value={pooldata.pid}
                  onChange={ handleChange} 
                >
                  <option value={'0'}>Bronze</option>
                  <option value={'1'}>Silver</option>
                  <option value={'2'}>Gold</option>
                  <option value={'3'}>Platinum</option>
                  <option value={'4'}>Diamond</option>
                </select>
                </div>
          <label className="mb-2" >Plan Amount</label>
            <div class="input-group mb-3">             
              <input type="text" class="form-control" id="poolprice" value={pooldata.poolprice}  onChange={ handleChange}   />
             
            </div>
            {showerror && showerror.poolprice !="" &&
                  <p style={{color:'yellow','fontSize':'15px'}}>{showerror.poolprice}</p>
                  }
          <label className="mb-2" >Referral Percentage</label>
            <div class="input-group mb-3">             
              <input type="text" class="form-control" id="referpercent" value={pooldata.referpercent} onChange={ handleChange}    />
             
            </div>
            {showerror && showerror.referpercent !="" &&
                  <p style={{color:'yellow','fontSize':'15px'}}>{showerror.referpercent}</p>
                  }
                  <label className="mb-2" >Referral CountLimit <span style={{"font-size": 'small'}}>Eg:0,1,2,3,4</span></label>
                 
            <div class="input-group mb-3">             
              <input type="text" class="form-control" id="referalcountlimit" value={pooldata.referalcountlimit} onChange={ handleChange}    />
             
            </div>
            {showerror && showerror.referalcountlimit !="" &&
                  <p style={{color:'yellow','fontSize':'15px'}}>{showerror.referalcountlimit}</p>
                  }
                  <label className="mb-2" >percentageLimit <span style={{"font-size": 'small'}}>Eg:100,200,250,300,350</span></label>
            <div class="input-group mb-3">             
              <input type="text" class="form-control" id="percentageLimit" value={pooldata.percentageLimit} onChange={ handleChange}    />
             
            </div>
            {showerror && showerror.percentageLimit !="" &&
                  <p style={{color:'yellow','fontSize':'15px'}}>{showerror.percentageLimit}</p>
                  }
            <div className="text-center">
            <button disabled={regbtn} class="btn primary_btn mt-3 p-3 py-2"  onClick={() => handleSubmit()}>{regbtn && <i class="fas fa-spinner fa-spin"></i>}{t('SUBMIT')}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Addplanmodal;