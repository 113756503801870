
import {React,useState,useEffect} from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
const Sharelink = (props) => {




  
    
   
   
   
  
  
  return (
    <div
    className="modal fade standard_popup walletpopup"
    id="my_share_modal"
    tabIndex={-1}
    aria-labelledby="connect_walletModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="connect_walletModalLabel">
         Share Referral
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        <div className="modal-body">
          <div style={{"display":"flex","justify-content": "space-evenly"}}>
            
          <WhatsappShareButton  url={props.referrallink} >
                              <WhatsappIcon size={50} round={true} />
                            </WhatsappShareButton>
                            <TelegramShareButton  url={props.referrallink} >
                              <TelegramIcon size={50} round={true} />
                            </TelegramShareButton>
                            <FacebookShareButton
                            url={props.referrallink} 
                            >
                              <FacebookIcon size={50} round={true} />
                            </FacebookShareButton>
                            <TwitterShareButton
                            url={props.referrallink}
                            >
                              <TwitterIcon size={50} round={true} />
                            </TwitterShareButton>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Sharelink;