import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import WalletModal from "./WalletModal";
import Mywallet from "./Mywallet";
import Mystandard from "./Standard";
import Web3 from "web3";
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "../reducers/Actions";
import { connection } from "../helper/connection";
import ABI from "../ABI/Abi.json"
import { toFixedFormat } from "../helper/custommath";
import { useTranslation } from "react-i18next";
import { selectLang } from "../reducers/Actions";
import { isEmpty } from "../helper/isEmpty";
import TokenAbi from "../ABI/TokenAbi.json";
import config from "../config/config";
import {
  type PublicClient,
  type WalletClient,
  useWalletClient,
  useAccount,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";

import {
  useEthersSigner,
  walletClientToSigner,
} from "../pages/ethersConnect.js";
import { toastAlert } from "../helper/toastAlert";
export default function Navbar(props) {
  const { t, i18n } = useTranslation();
  //state

  const [useraddress, setuseraddress] = useState("");
  const [language, setlanguage] = useState("en");
  const [admin, setadmin] = useState(false);
  const [accounts, setAccounts] = useState("");
  const [userdata, setuserdata] = useState([]);
  const [plancheck, setplancheck]= useState(false);
  //redux
  const dispatch = useDispatch();
  const walletConnection = useSelector((state) => state.walletConnection);
  const ReduxLanguage = useSelector((state) => state.language);

  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
    useSwitchNetwork();
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });
  useAccount({
    onDisconnect: () => {
      localStorage.clear();
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
    },
  });

  useEffect(() => {
    if (chain && chain.id !== config.NetworkId) {
      dispatch(
        setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true",
        })
      );
    } else {
      dispatch(
        setWallet({
          network: chainId,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
          isChange: "false",
        })
      );
    }
  }, [walletClient, chain?.network]);
  //func
  async function setConnection() {
    // var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
    // if (WEB3_CONNECT_CACHED_PROVI:DER) {
    //   var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
    //   if (connnector == "injected" || connnector == "walletconnect" || connnector === "binancechainwallet"
    //     || connnector == "walletlink") {
    //     var get = await connection();
    //     dispatch(setWallet(get));
    //     getuserBalance()
    //   }
    // }
    if (walletClient) {
      var get = await connection();

      console.log(get, "gettttttt");
      var { signer, transport } = walletClientToSigner(walletClient);

      var web3 = new Web3(transport);

      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
        })
      );
    }
  }

  useEffect(() => {
    var url = window.location.href;
    console.log(url, "uriii33333333");
    var splitup = url.split("/");
    console.log(splitup, "splitup124");
    var check = splitup.includes("evnytfyfyfgjhghyurd5y");
    if (check == true) {
      setadmin(true);
    } else {
      setadmin(false);
    }
    setConnection();
  }, [walletConnection.network, walletClient]);

  // useEffect(() => {
  //   if (walletConnection && walletConnection.provider?.on) {

  //     const handleAccountsChanged = (accounts) => {
  //        setAccounts(accounts);
  //       console.log('accounts-accounts', accounts)
  //       dispatch(setWallet({
  //         network: walletConnection.network,
  //         web3: walletConnection.web3,
  //         address: accounts[0],
  //         provider: walletConnection.provider,
  //         connect: "yes"
  //       }));
  //     };

  //     const handleChainChanged = (chainId) => {
  //       // setChainId(chainId);
  //       console.log('chainId-chainId', chainId)
  //       dispatch(setWallet({
  //         network: chainId,
  //         web3: walletConnection.web3,
  //         address: walletConnection.address,
  //         provider: walletConnection.provider,
  //         connect: "yes"
  //       }));

  //     };

  //     walletConnection.provider.on("accountsChanged", handleAccountsChanged);
  //     walletConnection.provider.on("chainChanged", handleChainChanged);

  //     return () => {
  //       if (walletConnection.provider.removeListener) {
  //         walletConnection.provider.removeListener("accountsChanged", handleAccountsChanged);
  //         walletConnection.provider.removeListener("chainChanged", handleChainChanged);

  //       }
  //     };
  //   }
  // }, [walletConnection]);
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  const handleLanguage = (e) => {
    console.log(e.target.value, "valuuuu");
    let data = {
      code: e.target.value,
    };
    setlanguage(e.target.value);
    if (data.code == "2r" || data.code == "ur") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
    }
    dispatch(selectLang(data));
    localStorage.setItem("lang", e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  async function getuserInfo() {
    try{
      var WEB3 = await connection();
      if(WEB3 && WEB3.address!=''){
        var contractAddress = config.contractAddress
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI,contractAddress);
        var currAddr = WEB3.address;
        var palnlevel = ['0','1','2','3','4']
        var userarr=[]
        for (var i in palnlevel ){
          var pid=palnlevel[i]
          var userplan= await Contract.methods.userInfo(pid,currAddr).call();
          console.log(userplan,'userpan')
          var obj={}
          obj.paln=palnlevel[i];
          obj.isExist=userplan.isExist
          userarr.push(obj)
        }
        console.log(userarr,'userarr237')
        setuserdata(userarr)
        if(userarr && userarr[0] && userarr[1]&& userarr[2]&& userarr[3]&& userarr[4]&& userarr[0].isExist==false &&userarr[1].isExist==false && userarr[2].isExist==false && userarr[3].isExist==false &&userarr[4].isExist==false){
          setplancheck(true)
        }
        else{
          setplancheck(false)
        }
      }
    }
    catch(err){
    
    }
      }
  useEffect(() => {
    getuserInfo();
    }, [walletConnection ,walletConnection.address]);
  useEffect(() => {
    if (!isEmpty(ReduxLanguage)) {
      let lang = localStorage.getItem("lang");

      i18n.changeLanguage(lang);
      setlanguage(lang);

      if (lang == "ar" || lang == "ur") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      } else {
        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      }
    }
  }, [ReduxLanguage]);
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <div className="container">
          <div className="navbar_left_menu">
            <Link className="navbar-brand" to="/">
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </Link>
            {/* <Link className="nav-link" to="/">
              <i className="fas fa-home me-1"></i> <span>Home</span>
            </Link> */}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                { walletConnection &&
                walletConnection.connect === "yes" &&
                walletConnection.address &&
                walletConnection.address !== "" && plancheck== false &&
             
                <div className="dropdown">
                  <a
                    className="primary_btn dropdown-toggle dashboard_dropdown_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Dashboard
                  </a>
                  <ul
                    className="dropdown-menu dashboard_dropdown"
                    aria-labelledby="dropdownMenuLink"
                  >
                    {userdata && userdata[0] && userdata[0].isExist==true &&
                    <li>
                      <Link
                        className="bronze_button dash_button"
                        to="/dashboard-bronze"
                      >
                        Bronze
                      </Link>
                    </li>
}
          
{userdata && userdata[1] && userdata[1].isExist==true &&          <li>
                      <Link
                        className="silver_button dash_button"
                        to="/dashboard-silver"
                      >
                        Silver
                      </Link>
                    </li>
}
{userdata && userdata[2] && userdata[2].isExist==true &&          <li>
                      <Link
                        className="gold_button dash_button"
                        to="/dashboard-gold"
                      >
                        Gold
                      </Link>
                    </li>
}
{userdata && userdata[3] && userdata[3].isExist==true &&                     <li>
                      <Link
                        className="platinum_button dash_button"
                        to="/dashboard-platinum"
                      >
                        Platinum
                      </Link>
                    </li>
}
{userdata && userdata[4] && userdata[4].isExist==true &&  <li>
                      <Link
                        className="diamond_button dash_button"
                        to="/dashboard-diamond"
                      >
                        Diamond
                      </Link>
                    </li>
}
                  </ul>
                </div>
   }
                {console.log(admin, "admin")}

                {admin == false && (
                  <button
                    className="primary_btn choose_std"
                    data-bs-toggle="modal"
                    data-bs-target="#Standards"
                  >
                    {t("STANDARDS")}
                  </button>
                )}
                {/* {walletConnection && walletConnection.connect === "no" &&          */}
               

                {/* {walletConnection && walletConnection.connect === "no" &&          */}
                

                {walletConnection &&
                walletConnection.connect === "yes" &&
                walletConnection.address &&
                walletConnection.address !== "" ? (
                  <>
                    <button
                      className="primary_btn"
                      admin
                      data-bs-toggle="modal"
                      data-bs-target="#my_wallet_modal"
                    >
                      {walletConnection &&  walletConnection.address ? walletConnection.address.slice(0,14)+'....':''}
                    </button>
                  </>
                ) : (
                  <>
                    {" "}
                    <button
                      className="primary_btn"
                      data-bs-toggle="modal"
                      data-bs-target="#connect_wallet_modal"
                    >
                      {t("CONNECT_WALLET")}
                    </button>
                  </>
                )}

                {/* <select
                  class="form-select lang_select"
                  aria-label="Default select example"
                  value={language}
                  onChange={ handleLanguage} 
                >
                  <option value={'en'}>Eng</option>
                  <option value={'ch'}>Ch</option>
                  <option value={'ko'}>Kor</option>
                </select> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Choose Standard Modal */}

      <Mystandard />
      {/* Connect Wallet Modal */}
      <WalletModal />

      {/* my Wallet Modal */}
      <Mywallet />
    </>
  );
}
