import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import AOS from "aos";
import "aos/dist/aos.css";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Bronze(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
    <div className="dash_bg">
      <ScrollToTopOnMount />
      <Navbar />
      <div className="dashboard_boxes">
        <div className="container">
          {/* <div className="dashboard_flx">
                <div className="dashboard_box">
                 1111
                </div>
                <div className="dashboard_box">
                 222
                </div>
                <div className="dashboard_box">
                 333
                </div>
                <div className="dashboard_box">
                 444
                </div>
                <div className="dashboard_box">
                 5555
                </div>
                <div className="dashboard_box">
                 6666
                </div>
                <div className="dashboard_box">
                 7777
                </div>
                <div className="dashboard_box">
                 8888
                </div>
                <div className="dashboard_box">
                 9999
                </div>
                <div className="dashboard_box">
                 0000
                </div>
                
             </div> */}
          <div class="dashboard_flx">
            <div class="box std_box">
              <div>
              <h5>Diamond</h5>
              <p>Current Standard</p>
              </div>
            </div>
            <div class="box id_box">
              <div>
              <h5>72</h5>
              <p>My ID</p>
              </div>
              <div className="img_box" >
              <img src={require("../assets/images/prf.png")} alt="Image" className="img-fluid" />
              </div>
            </div>
            <div class="box ref_box">
              <div>
              <h5>250</h5>
              <p>Direct Referrals</p>
              </div>
              <div className="img_box" >
              <img src={require("../assets/images/refferals.png")} alt="Image" className="img-fluid" />
              </div>
            </div>
            <div class="box lvl_box">
              <div>
              <h5>2</h5>
              <p>Level</p>
              </div> 
              <div>
              <h5>2.5%</h5>
              <p>Daily</p>
              </div> 
            </div>
            <div class="box refin_box">
              <div>
              <h5>4730</h5>
              <p>Referral Income</p>
              </div>
              <div className="img_box" >
              <img src={require("../assets/images/income.png")} alt="Image" className="img-fluid" />
              </div>
            </div>


            <div class="box right_box inv_box">
              <div>
              <h5>27531.83</h5>
              <p>Current Investment</p>
              </div>
              <div className="img_box" >
              <img src={require("../assets/images/investment.png")} alt="Image" className="img-fluid" />
              </div>
            </div>

            <div class="box right_box ear_box">
            <div>
              <h5>27531.83</h5>
              <p>Current Investment</p>
              </div>
              <button className="btn primary_btn">Claim</button>
            </div>

            

            <div class="box right_box tot_box">
              <div>
              <h5>54789.21</h5>
              <p>Total Payout to Wallet (Referral + Claimed)</p>
              </div>
              <div className="img_box" >
              <img src={require("../assets/images/wallet.png")} alt="Image" className="img-fluid" />
              </div>
            </div>

            

            <div class="box right_box prg_box">
              <div className="wid_th" >
              <div class="progress">
               <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" 
                  aria-label="Animated striped example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width:"25%"}}>
               </div>
                </div>
              <p>Progresss to Earnings ($100 Profit or $200 Total Balance)</p>
              </div>
              <button className="btn primary_btn">Upgrade</button>
            </div>

            
            <div class="box right_box earnmore_box">
              <div>
                <h6>Earn More</h6>
                <a href="#">https://elitevisionnetwork.io/i/g9pnqp/
                {/* <img src={require("../assets/images/copy.png")} alt="Image" className="img-fluid ms-2" /> */}
                </a>
              </div>
              <div className="d-flex" >
              <img src={require("../assets/images/facebook.png")} alt="Image" className="img-fluid" />
              <img src={require("../assets/images/twitter.png")} alt="Image" className="img-fluid ms-2" />
              </div>
            </div>

            
          </div>
        </div>
      </div>
      <Footer />
    </div>
    </div>
  );
}
