import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import NavbarStandard from "../components/Navbar-Standard.js";
import { Scrollbars } from "react-custom-scrollbars";
import { useTranslation } from "react-i18next";
import { toastAlert } from "../helper/toastAlert";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import Joinnowmodal from "../components/joinnow.js";
import { checkuser } from "../helper/connection";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import ABI from "../ABI/Abi.json";
import config from "../config/config";
import { connection } from "../helper/connection";
import Levelstandards from "../components/levelstandards.js";
import Web3 from "web3";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function StandardBronze(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userstatus, setUserstatus] = useState(false);
  const [address, setAddress] = useState("");
  const [plandata, setplandata] = useState([]);
  useEffect(() => {
    AOS.init();
    getplanData();
  }, []);

  async function userdata() {
    var data = { planid: "0" };
    var checkdata = await checkuser(data);
    console.log(checkdata, "checkdata");
    if (checkdata && checkdata.exist) {
      setUserstatus(checkdata.exist);
    } else if (checkdata && !checkdata.exist) {
      setUserstatus(false);
    }
  }

  useEffect(() => {
    userdata();
  }, [walletConnection.address, userstatus]);

  useEffect(() => {
    console.log("fgggggg");
    const parsed = queryString.parse(location.search);
    console.log(parsed, "parsed");
    if (parsed && parsed.referral && parsed.referral != "") {
      setAddress(parsed.referral);
      window.$("#Standards1").modal("show");
    }
  }, []);

  async function getplanData() {
    try {
      // var WEB3 = await connection();
      // if(WEB3 && WEB3.address!=''){
      var WEB3 = new Web3(config.netWorkUrl);
      var contractAddress = config.contractAddress;
      var web3 = WEB3;
      var Contract = new web3.eth.Contract(ABI, contractAddress);
      var planarr = [];
      var planinfo = await Contract.methods.getPoolDetails("0").call();

      var planobj = {};
      planobj.planid = planinfo[0];
      planobj.planamount = planinfo[1] / 10 ** 18;
      planobj.referralincome = planinfo[2];
      planobj.planintrest = planinfo._percentageLimit[4] / 100;
      planobj._percentageLimit = planinfo._percentageLimit;
      planobj._referCountsLimit = planinfo._referCountsLimit;
      planarr.push(planobj);
      console.log(planarr, "userarr78");
      setplandata(planarr);
    } catch (err) {
      //}
      console.log(err, "errr");
    }
  }

  const handlePlan = async () => {
    try {
      if (walletConnection && walletConnection.address == "") {
        // toastAlert('error', "Please connect your wallet", 'invalid');
        window.$("#connect_wallet_modal").modal("show");
        return;
      } else {
        window.$("#Standards1").modal("show");
      }
    } catch (err) {}
  };
  // useEffect(() => {
  //   getplanData();
  // }, [walletConnection.address]);
  return (
    <div>
      <ScrollToTopOnMount />
      <NavbarStandard />
      <div className="bronze_box">
        <div className="container">
          <Levelstandards />
          <div className="home_std_tab_content mb-5">
            <h2 className="h2tag">{t("BRONZE")}</h2>
            <ul className="std_details_list">
              {/* <li>{t("FIXED_INVEST")} - USDT 10</li>
            <li>
              {t("REFERAL_INCOME")} - 10% {t("PER_REFERAL")}
            </li>
            <li>
              {" "}
              {t("LEVEL_BASED")} - {t("UP_TO")} 2.5% {t("DAILY")}
            </li> */}
              <li>
                {t("FIXED_INVEST")} - USDT{" "}
                {plandata && plandata[0] && plandata[0].planamount
                  ? plandata[0].planamount
                  : "-"}
              </li>
              <li>
                {t("REFERAL_INCOME")} -{" "}
                {plandata && plandata[0] && plandata[0].referralincome
                  ? plandata[0].referralincome
                  : "-"}
                % {t("PER_REFERAL")}
              </li>
              <li>
                {" "}
                {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                {plandata && plandata[0] && plandata[0].planintrest
                  ? plandata[0].planintrest
                  : "-"}
                % {t("DAILY")}
              </li>
            </ul>
            <div className="dashboard_bronze_wrap">
              {userstatus == true && (
                <Link class="btn secondary_btn mt-3" to="/dashboard-bronze">
                  Dashboard
                </Link>
              )}
              {userstatus == false && (
                <button
                  class="btn secondary_btn mt-3"
                  // data-bs-toggle="modal"
                  // data-bs-target="#Standards1"
                  onClick={() => handlePlan()}
                >
                  {t("JOIN_NOW")}
                </button>
              )}
            </div>
            {/* <div className="table-responsive">
              <div className="standard_panel standard_panel_bronze">
                <div className="standard_row">
                  <div>
                    <div className="standard_column_one">
                      <h2>2.5%</h2>
                      <p>Daily</p>
                    </div>
                  </div>
                  <div>
                    <h3>LEVEL 4</h3>
                    <p>(Minimum 8 Direct Referrals)</p>
                  </div>
                  <div>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                  </div>
                </div>
                <div className="standard_row">
                  <div>
                    <div className="standard_column_two">
                      <h2>2.2%</h2>
                      <p>Daily</p>
                    </div>
                  </div>
                  <div>
                    <h3>LEVEL 3</h3>
                    <p>(Minimum 5 Direct Referrals)</p>
                  </div>
                  <div>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>                  
                  </div>
                </div>
                <div className="standard_row">
                  <div>
                    <div className="standard_column_three">
                      <h2>2%</h2>
                      <p>Daily</p>
                    </div>
                  </div>
                  <div>
                    <h3>LEVEL 2</h3>
                    <p>(Minimum 3 Direct Referrals)</p>
                  </div>
                  <div>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>
                    <i class="fas fa-user"></i>                 
                  </div>
                </div>
                <div className="standard_row">
                  <div>
                    <div className="standard_column_four">
                      <h2>1.8%</h2>
                      <p>Daily</p>
                    </div>
                  </div>
                  <div>
                    <h3>LEVEL 1</h3>
                    <p>(Minimum 1 Direct Referrals)</p>
                  </div>
                  <div>
                    <i class="fas fa-user"></i>                 
                  </div>
                </div>
                <div className="standard_row">
                  <div>
                    <div className="standard_column_five">
                      <h2>1.5%</h2>
                      <p>Daily</p>
                    </div>
                  </div>
                  <div>
                    <h3>LEVEL 0</h3>
                    <p>(Zero Referrals) </p>
                  </div>
                  <div>
                    -
                  </div>
                </div>
              </div>
            </div> */}

<div className="table-responsive">
  <div className="standard_panel standard_panel_bronze">
    <div className="standard_row">
      <div>
        <div className="standard_column_one">
          <h2>{plandata && plandata[0] && plandata[0]._percentageLimit
      ? (plandata[0]._percentageLimit[4]/100+"%")
      : "-"}</h2>
          <p>Daily</p>
        </div>
      </div>
      <div>
        <h3>LEVEL 4</h3>
        <p>(Minimum  {plandata && plandata[0] && plandata[0]._referCountsLimit
      ? plandata[0]._referCountsLimit[4]
      : "-"} Direct Referrals)</p>
      </div>
      <div>
        {
         Array.from({ length: plandata && plandata[0] && plandata[0]._referCountsLimit && plandata[0]._referCountsLimit[4] }, (_, index) => (
          <i class="fas fa-user"></i>
          ))
        }
{/*                               
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i>
        <i class="fas fa-user"></i> */}
      </div>
    </div>
    <div className="standard_row">
      <div>
        <div className="standard_column_two">
        <h2>{plandata && plandata[0] && plandata[0]._percentageLimit
      ? (plandata[0]._percentageLimit[3]/100+"%")
      : "-"}</h2>
          <p>Daily</p>
        </div>
      </div>
      <div>
        <h3>LEVEL 3</h3>
        <p>(Minimum  {plandata && plandata[0] && plandata[0]._referCountsLimit
      ? plandata[0]._referCountsLimit[3]
      : "-"} Direct Referrals)</p>
      </div>
   
      <div>
 
      {
         Array.from({ length: plandata && plandata[0] && plandata[0]._referCountsLimit && plandata[0]._referCountsLimit[3] }, (_, index) => (
          <i class="fas fa-user"></i>
          ))
        }
      </div>
    </div>
    <div className="standard_row">
      <div>
        <div className="standard_column_three">
        <h2>{plandata && plandata[0] && plandata[0]._percentageLimit
      ? (plandata[0]._percentageLimit[2]/100+"%")
      : "-"}</h2>
          <p>Daily</p>
        </div>
      </div>
      <div>
        <h3>LEVEL 2</h3>
        <p>(Minimum  {plandata && plandata[0] && plandata[0]._referCountsLimit
      ? plandata[0]._referCountsLimit[2]
      : "-"} Direct Referrals)</p>
      </div>
      <div>
      {
         Array.from({ length: plandata && plandata[0] && plandata[0]._referCountsLimit && plandata[0]._referCountsLimit[2] }, (_, index) => (
          <i class="fas fa-user"></i>
          ))
        }
      </div>
    </div>
    <div className="standard_row">
      <div>
        <div className="standard_column_four">
        <h2>{plandata && plandata[0] && plandata[0]._percentageLimit
      ? (plandata[0]._percentageLimit[1]/100+"%")
      : "-"}</h2>
          <p>Daily</p>
        </div>
      </div>
      <div>
        <h3>LEVEL 1</h3>
        <p>(Minimum  {plandata && plandata[0] && plandata[0]._referCountsLimit
      ? plandata[0]._referCountsLimit[1]
      : "-"} Direct Referrals)</p>
      </div>
      <div>
      {
         Array.from({ length: plandata && plandata[0] && plandata[0]._referCountsLimit && plandata[0]._referCountsLimit[1] }, (_, index) => (
          <i class="fas fa-user"></i>
          ))
        }
      </div>
    </div>
    <div className="standard_row">
      <div>
        <div className="standard_column_five">
        <h2>{plandata && plandata[0] && plandata[0]._percentageLimit
      ? (plandata[0]._percentageLimit[0]/100+"%")
      : "-"}</h2>
          <p>Daily</p>
        </div>
      </div>
      <div>
        <h3>LEVEL 0</h3>
        
        <p>(Minimum {plandata && plandata[0] && plandata[0]._referCountsLimit
      ? plandata[0]._referCountsLimit[0]
      : "-"} Direct Referrals)</p>
      </div>
      <div>
      {
         Array.from({ length: plandata && plandata[0] && plandata[0]._referCountsLimit && plandata[0]._referCountsLimit[0] }, (_, index) => (
          <i class="fas fa-user"></i>
          ))
        }
      </div>
    </div>
  </div>
</div>
            {/* <div className="img_box">
              <img
                src={require("../assets/images/bronze.png")}
                alt="Image"
                className="img-fluid mobile_none"
              />
              <img
                src={require("../assets/images/bronze_mobile.png")}
                alt="Image"
                className="img-fluid desktop_none"
              />
            </div>
            <ul className="desktop_none mb-5 std_details_list">
              <li> {t("LEVEL_ZERO")}</li>
              <li>{t("BRONZE_L1")}</li>
              <li>{t("BRONZE_L2")}</li>
              <li>{t("BRONZE_L3")}</li>
              <li>{t("BRONZE_L4")}</li>
            </ul> */}
          </div>
        </div>
      </div>
      <Joinnowmodal planid={"0"} address={address} planname={"BRONZE"} />

      <Footer />
    </div>
  );
}
