import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import { toastAlert } from "../helper/toastAlert";
import { useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getlasttransaction } from "../helper/transaction";
import config from "../config/config";
import Joinnowmodal from "../components/joinnow.js";
import { connection } from "../helper/connection";
import ABI from "../ABI/Abi.json";
import { useSelector, useDispatch } from "react-redux";
import Web3 from "web3";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const [userdata, setuserdata] = useState([]);
  const [userstatus, setuserstatus] = useState([]);
  const walletConnection = useSelector((state) => state.walletConnection);
  const [plandata, setplandata] = useState([]);
  const [refferal, setrefferal] = useState("");
  const [planid, setplanid] = useState();
  const [planname, setplanname] = useState("");
  const [homeData, sethomeData] = useState([]);
  const [selectedplan, setselectedplan] = useState(0);
  const [selectedlevel, setselectedlevel] = useState(0);

  useEffect(() => {
    AOS.init();
  }, []);

  async function getData() {
    try {
      var data = await getlasttransaction(setuserdata);
      console.log(userdata, "datattat");
    } catch (err) { }
  }

  useEffect(() => {
    getData();
    getplanData();
  }, []);

  useEffect(() => {
    sethomedisplay();
  }, [selectedplan, selectedlevel]);

  async function sethomedisplay() {
    try {
      console.log(selectedplan, "selectedplan");
      var web3 = new Web3(config.netWorkUrl);
      var contractAddress = config.contractAddress;
      var Contract = new web3.eth.Contract(ABI, contractAddress);
      var palnlevel = selectedplan;
      var homeDatas = [];
      var planinfo = await Contract.methods.getPoolDetails(palnlevel).call();
      console.log(planinfo, "fyrthtyujty");
      var planobj = {};
      planobj.planid = planinfo[0];
      planobj.planName =
        planinfo[0] == 0
          ? "Bronze"
          : planinfo[0] == 1
            ? "Silver"
            : planinfo[0] == 2
              ? "Gold"
              : planinfo[0] == 3
                ? "Platinum"
                : planinfo[0] == 4
                  ? "Diamond"
                  : "";

      planobj.Level = selectedlevel;
      planobj.planamount = planinfo[1] / 10 ** 18;
      planobj.referralincome = planinfo[2];
      planobj.percentageLimit =
        planinfo?._percentageLimit?.[selectedlevel] / 100;
      planobj.referCountsLimit = planinfo?._referCountsLimit?.[selectedlevel];
      homeDatas.push(planobj);
      console.log(homeDatas, "homeDatashomeDdhdeghdfbgdratashomeDatas");
      sethomeData(homeDatas?.[0]);
    } catch (err) {
      console.log(err, "errr");
    }
  }

  async function getplanData() {
    try {
      // var WEB3 = await connection();
      var WEB3 = new Web3(config.netWorkUrl);
      console.log(WEB3, "WEB352");
      var contractAddress = config.contractAddress;
      var web3 = WEB3;
      var Contract = new web3.eth.Contract(ABI, contractAddress);
      console.log(Contract, "Contract");
      var palnlevel = ["0", "1", "2", "3", "4"];
      var planarr = [];
      for (var i in palnlevel) {
        var planinfo = await Contract.methods
          .getPoolDetails(palnlevel[i])
          .call();

        var planobj = {};
        planobj.planid = planinfo[0];
        planobj.planamount = planinfo[1] / 10 ** 18;
        planobj.referralincome = planinfo[2];
        planobj.planintrest = planinfo._percentageLimit[4] / 100;
        planobj._percentageLimit = planinfo._percentageLimit;
        planobj._referCountsLimit = planinfo._referCountsLimit;
        planarr.push(planobj);
      }
      console.log(planarr, "userarr59");
      setplandata(planarr);
    } catch (err) {
      console.log(err, "errr");
    }
  }
  async function getuserInfo() {
    try {
      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);
        var currAddr = WEB3.address;
        var palnlevel = ["0", "1", "2", "3", "4"];
        var userarr = [];
        for (var i in palnlevel) {
          var pid = palnlevel[i];
          var userplan = await Contract.methods.userInfo(pid, currAddr).call();
          console.log(userplan, "userpan");
          var obj = {};
          obj.paln = palnlevel[i];
          obj.isExist = userplan.isExist;
          userarr.push(obj);
        }
        console.log(userarr, "1876serw");
        setuserstatus(userarr);
      }
    } catch (err) { }
  }
  function clickjoin() {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    getuserInfo();
  }, [walletConnection.address]);
  const handlePlan = async (id) => {
    console.log(walletConnection, "walletConnection102");
    if (walletConnection && walletConnection.address == "") {
      // toastAlert("error", "Please connect your wallet", "invalid");
      window.$("#connect_wallet_modal").modal("show");
      return;
    }
    try {
      var planval = "BRONZE";
      if (id == "0") {
        planval = "BRONZE";
      } else if (id == "1") {
        planval = "SILVER";
      } else if (id == "2") {
        planval = "GOLD";
      } else if (id == "3") {
        planval = "PLATINUM";
      } else if (id == "4") {
        planval = "DIAMOND";
      }
      console.log(planval, "planval");
      setplanname(planval);
      setplanid(id);
      window.$("#Standards1").modal("show");
      // var WEB3 = await connection();
      // console.log(WEB3, "WEB3WEB3");
      // if (WEB3 && WEB3.address != "") {
      //   console.log(WEB3, "WEB3WEB32");
      //   var contractAddress = config.contractAddress;
      //   var web3 = WEB3.web3;
      //   var Contract = new web3.eth.Contract(ABI, contractAddress);
      //   var currAddr = WEB3.address;
      //   console.log(currAddr, "currAddr");
      //   var ownerAddress = await Contract.methods.OwnerWallet().call();
      //   console.log(ownerAddress, "ownerAddress2312");
      //   var admindata = await Contract.methods
      //     .userInfo(id, ownerAddress)
      //     .call();
      //   console.log(admindata, "admindatazzzz");
      //   if (admindata && admindata.id) {
      //     console.log("EVN" + admindata.id + planval, "pvallll");
      //     setrefferal("EVN" + admindata.id + planval);
      //     setplanid(id);
      //     window.$("#Standards1").modal("show");
      //   }
      // }
    } catch (err) { }
  };

  console.log(homeData, "homeData");
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-8"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <h1>{t("HOME_TITLE")}</h1>
              {/* <h5>{t("TITLE_DESC")}</h5>
              <button className="primary_btn">{t("LEARN_MORE")}</button> */}
              <ul className="std_details_list">
                <li>
                  Earn interest EVERYDAY on your investment which is directly
                  deposited into your own wallet that is in your control.
                </li>
                <li>
                  Interest deposited via blockchain smart contract technology
                </li>
                <li>Your earnings can be withdrawn anytime with no limits</li>
              </ul>
              {walletConnection && walletConnection.address != "" ? (
                <button
                  className="primary_btn mt-3"
                  onClick={() => clickjoin()}
                >
                  Join Now
                </button>
              ) : (
                <button
                  className="primary_btn mt-3"
                  onClick={() => handlePlan("")}
                >
                  Join Now
                </button>
              )}
              <img
                src={require("../assets/images/banner_img_mobile.png")}
                alt="Image"
                className="img-fluid d_d_none mt-4 m-auto"
              />
            </div>
          </div>
          <div className="banner_img">
            <img
              src={require("../assets/images/banner_img_mobile.png")}
              alt="Image"
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        {/* Investment Section */}
        <section className="invest_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img
                  src={require("../assets/images/img_01.png")}
                  alt="Image"
                  className="img-fluid"
                />
              </div>
              <div
                className="col-lg-6 mb-lg-0 mb-5"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title">{t("TITLE_DESC")}</h2>
                <p className="inner_para">{t("CONTENT_1")}</p>
                <p className="inner_para">{t("CONTENT_2")}</p>
              </div>
            </div>
          </div>
        </section>

        {/* 2X Rewards Section */}
        <section className="invest_section">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-6 mb-lg-0 mb-5"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title">
                  Elite Vision's Smart Investing Plan For Doubled Rewards
                </h2>
                <p className="inner_para">
                  Elite Vision holds the promise that investing in this network
                  offers the potential for your initial investment to double
                  upon receiving rewards. After a successful cycle, users must
                  rejoin to continue their investment journey, a rule applicable
                  to all available plans.
                </p>
              </div>
              <div
                className="col-lg-6 text-center order-lg-last order-first"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <img
                  src={require("../assets/images/2x_reward.png")}
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="invest_section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 mb-5"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title">
                  Elite Vision Network Revenue Generation
                </h2>
                <p className="inner_para">
                  Elite Vision Network (EVN) is a community-driven fund
                  operating under Bharat Green Energy Pvt Ltd that leverages the
                  power of blockchain technology to democratize a Climate
                  Conscious initiative in the Plastic Waste Management Sector.{" "}
                </p>
                <p className="inner_para">
                  This Green Initiative partially sponsored by the Indian
                  Government, under the Swachh Bharat campaign spreads across 3
                  states with operations focused primarily in Tier 2 & Tier 3
                  Cities.
                </p>
                <p className="inner_para">
                  The project aims to reduce and recycle plastics by partnering
                  with Cement, Road Construction, & Alternate Fuels Industries.
                  The reclamation of the land by removing this landfill waste
                  that is being recycled is of tremendous value to the
                  government as well as society.
                </p>
                <p className="inner_para">
                  Know more about how EVN generates by joining any of the EVN
                  Standards. The detailed document is available inside the
                  dashboard to all supporters of the cause that have contributed
                  their investment.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Pyramid Section */}
        <section ref={ref}>
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title text-center"> Package Standards</h2>
                <ul
                  class="nav nav-pills package_tab_std"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active bronze_tabbtn"
                      id="pills-bronze-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-bronze"
                      type="button"
                      role="tab"
                      aria-controls="pills-bronze"
                      aria-selected="true"
                    >
                      <span>Bronze</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link silver_tabbtn"
                      id="pills-silver-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-silver"
                      type="button"
                      role="tab"
                      aria-controls="pills-silver"
                      aria-selected="false"
                    >
                      <span>Silver</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link gold_tabbtn"
                      id="pills-gold-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-gold"
                      type="button"
                      role="tab"
                      aria-controls="pills-gold"
                      aria-selected="false"
                    >
                      <span>Gold</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link platinum_tabbtn"
                      id="pills-platinum-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-platinum"
                      type="button"
                      role="tab"
                      aria-controls="pills-platinum"
                      aria-selected="false"
                    >
                      <span>Platinum</span>
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link diamond_tabbtn"
                      id="pills-diamond-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-diamond"
                      type="button"
                      role="tab"
                      aria-controls="pills-diamond"
                      aria-selected="false"
                    >
                      <span>Diamond</span>
                    </button>
                  </li>
                </ul>
                <div
                  class="tab-content home_std_tab_content"
                  id="pills-tabContent"
                >
                  <div
                    class="tab-pane fade show active"
                    id="pills-bronze"
                    role="tabpanel"
                    aria-labelledby="pills-bronze-tab"
                  >
                    <div className="bronze_box">
                      <h2 className="h2tag">{t("BRONZE")}</h2>
                      <ul className="std_details_list">
                        <li>
                          {t("FIXED_INVEST")} - USDT{" "}
                          {plandata && plandata[0] && plandata[0].planamount
                            ? plandata[0].planamount
                            : "-"}
                        </li>
                        <li>
                          {t("REFERAL_INCOME")} -{" "}
                          {plandata && plandata[0] && plandata[0].referralincome
                            ? plandata[0].referralincome
                            : "-"}
                          % {t("PER_REFERAL")}
                        </li>
                        <li>
                          {" "}
                          {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                          {plandata && plandata[0] && plandata[0].planintrest
                            ? plandata[0].planintrest
                            : "-"}
                          % {t("DAILY")}
                        </li>
                      </ul>
                      <div className="dashboard_bronze_wrap">
                        {/* {userstatus == true && (
                            <a
                              class="btn secondary_btn mt-3"
                              href="/dashboard-bronze"
                            >
                              Dashboard
                            </a>
                          )}
                          {userstatus == false && ( */}
                        {console.log(userstatus, "iiiiiiii")}

                        {userstatus &&
                          userstatus[0] &&
                          userstatus[0].isExist == true ? (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-bronze"
                          >
                            Dashboard
                          </a>
                        ) : (
                          <button
                            class="btn secondary_btn mt-3"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Standards1"

                            onClick={() => handlePlan("0")}
                          >
                            {t("JOIN_NOW")}
                          </button>
                        )}
                      </div>
                      <div className="table-responsive">
                        <div className="standard_panel standard_panel_bronze">
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_one">
                                <h2>
                                  {plandata &&
                                    plandata[0] &&
                                    plandata[0]._percentageLimit
                                    ? plandata[0]._percentageLimit[4] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 4</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[0] &&
                                  plandata[0]._referCountsLimit
                                  ? plandata[0]._referCountsLimit[4]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[0] &&
                                    plandata[0]._referCountsLimit &&
                                    plandata[0]._referCountsLimit[4],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                              {/*                               
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i>
                              <i class="fas fa-user"></i> */}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_two">
                                <h2>
                                  {plandata &&
                                    plandata[0] &&
                                    plandata[0]._percentageLimit
                                    ? plandata[0]._percentageLimit[3] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 3</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[0] &&
                                  plandata[0]._referCountsLimit
                                  ? plandata[0]._referCountsLimit[3]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>

                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[0] &&
                                    plandata[0]._referCountsLimit &&
                                    plandata[0]._referCountsLimit[3],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_three">
                                <h2>
                                  {plandata &&
                                    plandata[0] &&
                                    plandata[0]._percentageLimit
                                    ? plandata[0]._percentageLimit[2] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 2</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[0] &&
                                  plandata[0]._referCountsLimit
                                  ? plandata[0]._referCountsLimit[2]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[0] &&
                                    plandata[0]._referCountsLimit &&
                                    plandata[0]._referCountsLimit[2],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_four">
                                <h2>
                                  {plandata &&
                                    plandata[0] &&
                                    plandata[0]._percentageLimit
                                    ? plandata[0]._percentageLimit[1] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 1</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[0] &&
                                  plandata[0]._referCountsLimit
                                  ? plandata[0]._referCountsLimit[1]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[0] &&
                                    plandata[0]._referCountsLimit &&
                                    plandata[0]._referCountsLimit[1],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_five">
                                <h2>
                                  {plandata &&
                                    plandata[0] &&
                                    plandata[0]._percentageLimit
                                    ? plandata[0]._percentageLimit[0] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 0</h3>

                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[0] &&
                                  plandata[0]._referCountsLimit
                                  ? plandata[0]._referCountsLimit[0]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[0] &&
                                    plandata[0]._referCountsLimit &&
                                    plandata[0]._referCountsLimit[0],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-silver"
                    role="tabpanel"
                    aria-labelledby="pills-silver-tab"
                  >
                    <div className="bronze_box">
                      <h2 className="h2tag">{t("SILVER")}</h2>
                      <ul className="std_details_list">
                        <li>
                          {t("FIXED_INVEST")} - USDT{" "}
                          {plandata && plandata[1] && plandata[1].planamount
                            ? plandata[1].planamount
                            : "-"}
                        </li>
                        <li>
                          {t("REFERAL_INCOME")} -{" "}
                          {plandata && plandata[1] && plandata[1].referralincome
                            ? plandata[1].referralincome
                            : "-"}
                          % {t("PER_REFERAL")}
                        </li>
                        <li>
                          {" "}
                          {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                          {plandata && plandata[1] && plandata[1].planintrest
                            ? plandata[1].planintrest
                            : "-"}
                          % {t("DAILY")}
                        </li>
                      </ul>
                      <div className="dashboard_silver_wrap">
                        {/* <button class="btn secondary_btn mt-3" data-bs-toggle="modal" data-bs-target="#Standards1">{t('JOIN_NOW')}</button> */}

                        {/* {userstatus == true && (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-silver"
                          >
                            Dashboard
                          </a>
                        )}
                        {userstatus == false && ( */}

                        {userstatus &&
                          userstatus[1] &&
                          userstatus[1].isExist == true ? (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-silver"
                          >
                            Dashboard
                          </a>
                        ) : (
                          <button
                            class="btn secondary_btn mt-3"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Standards1"
                            onClick={() => handlePlan("1")}
                          >
                            {t("JOIN_NOW")}
                          </button>
                        )}
                        {/* )} */}
                      </div>
                      <div className="table-responsive">
                        <div className="standard_panel standard_panel_silver">
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_one">
                                <h2>
                                  {plandata &&
                                    plandata[1] &&
                                    plandata[1]._percentageLimit
                                    ? plandata[1]._percentageLimit[4] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 4</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[1] &&
                                  plandata[1]._referCountsLimit
                                  ? plandata[1]._referCountsLimit[4]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[1] &&
                                    plandata[1]._referCountsLimit &&
                                    plandata[1]._referCountsLimit[4],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_two">
                                <h2>
                                  {plandata &&
                                    plandata[1] &&
                                    plandata[1]._percentageLimit
                                    ? plandata[1]._percentageLimit[3] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 3</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[1] &&
                                  plandata[1]._referCountsLimit
                                  ? plandata[1]._referCountsLimit[3]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[1] &&
                                    plandata[1]._referCountsLimit &&
                                    plandata[1]._referCountsLimit[3],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_three">
                                <h2>
                                  {plandata &&
                                    plandata[1] &&
                                    plandata[1]._percentageLimit
                                    ? plandata[1]._percentageLimit[2] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 2</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[1] &&
                                  plandata[1]._referCountsLimit
                                  ? plandata[1]._referCountsLimit[2]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[1] &&
                                    plandata[1]._referCountsLimit &&
                                    plandata[1]._referCountsLimit[2],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_four">
                                <h2>
                                  {plandata &&
                                    plandata[1] &&
                                    plandata[1]._percentageLimit
                                    ? plandata[1]._percentageLimit[1] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 1</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[1] &&
                                  plandata[1]._referCountsLimit
                                  ? plandata[1]._referCountsLimit[1]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[1] &&
                                    plandata[1]._referCountsLimit &&
                                    plandata[1]._referCountsLimit[1],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_five">
                                <h2>
                                  {plandata &&
                                    plandata[1] &&
                                    plandata[1]._percentageLimit
                                    ? plandata[1]._percentageLimit[0] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 0</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[1] &&
                                  plandata[1]._referCountsLimit
                                  ? plandata[1]._referCountsLimit[0]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[1] &&
                                    plandata[1]._referCountsLimit &&
                                    plandata[1]._referCountsLimit[0],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-gold"
                    role="tabpanel"
                    aria-labelledby="pills-gold-tab"
                  >
                    <div className="bronze_box">
                      <h2 className="h2tag">{t("GOLD")}</h2>
                      <ul className="std_details_list">
                        {/* <li>{t("FIXED_INVEST")} - USDT 150</li>
                        <li>
                          {t("REFERAL_INCOME")} - 10% {t("PER_REFERAL")}
                        </li>
                        <li>
                          {t("LEVEL_BASED")}- {t("UP_TO")} 3.5% {t("DAILY")}
                        </li> */}
                        <li>
                          {t("FIXED_INVEST")} - USDT{" "}
                          {plandata && plandata[2] && plandata[2].planamount
                            ? plandata[2].planamount
                            : "-"}
                        </li>
                        <li>
                          {t("REFERAL_INCOME")} -{" "}
                          {plandata && plandata[2] && plandata[2].referralincome
                            ? plandata[2].referralincome
                            : "-"}
                          % {t("PER_REFERAL")}
                        </li>
                        <li>
                          {" "}
                          {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                          {plandata && plandata[2] && plandata[2].planintrest
                            ? plandata[2].planintrest
                            : "-"}
                          % {t("DAILY")}
                        </li>
                      </ul>
                      <div className="dashboard_gold_wrap">
                        {/* {userstatus == true && (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-gold"
                          >
                            Dashboard
                          </a>
                        )}
                        {userstatus == false && ( */}

                        {userstatus &&
                          userstatus[2] &&
                          userstatus[2].isExist == true ? (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-gold"
                          >
                            Dashboard
                          </a>
                        ) : (
                          <button
                            class="btn secondary_btn mt-3"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Standards1"
                            onClick={() => handlePlan("2")}
                          >
                            {t("JOIN_NOW")}
                          </button>
                        )}
                        {/* )} */}
                      </div>
                      <div className="table-responsive">
                        <div className="standard_panel standard_panel_gold">
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_one">
                                <h2>
                                  {plandata &&
                                    plandata[2] &&
                                    plandata[2]._percentageLimit
                                    ? plandata[2]._percentageLimit[4] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 4</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[2] &&
                                  plandata[2]._referCountsLimit
                                  ? plandata[2]._referCountsLimit[4]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[2] &&
                                    plandata[2]._referCountsLimit &&
                                    plandata[2]._referCountsLimit[4],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_two">
                                <h2>
                                  {plandata &&
                                    plandata[2] &&
                                    plandata[2]._percentageLimit
                                    ? plandata[2]._percentageLimit[3] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 3</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[2] &&
                                  plandata[2]._referCountsLimit
                                  ? plandata[2]._referCountsLimit[3]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[2] &&
                                    plandata[2]._referCountsLimit &&
                                    plandata[2]._referCountsLimit[3],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_three">
                                <h2>2.5%</h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 2</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[2] &&
                                  plandata[2]._referCountsLimit
                                  ? plandata[2]._referCountsLimit[2]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[2] &&
                                    plandata[2]._referCountsLimit &&
                                    plandata[2]._referCountsLimit[2],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_four">
                                <h2>
                                  {plandata &&
                                    plandata[2] &&
                                    plandata[2]._percentageLimit
                                    ? plandata[2]._percentageLimit[1] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 1</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[2] &&
                                  plandata[2]._referCountsLimit
                                  ? plandata[2]._referCountsLimit[1]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[2] &&
                                    plandata[2]._referCountsLimit &&
                                    plandata[2]._referCountsLimit[1],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_five">
                                <h2>
                                  {plandata &&
                                    plandata[2] &&
                                    plandata[2]._percentageLimit
                                    ? plandata[2]._percentageLimit[0] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 0</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[2] &&
                                  plandata[2]._referCountsLimit
                                  ? plandata[2]._referCountsLimit[0]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {" "}
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[2] &&
                                    plandata[2]._referCountsLimit &&
                                    plandata[2]._referCountsLimit[0],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-platinum"
                    role="tabpanel"
                    aria-labelledby="pills-platinum-tab"
                  >
                    <div className="bronze_box">
                      <h2 className="h2tag">{t("PLATINUM")}</h2>
                      <ul className="std_details_list">
                        {/* <li>{t("FIXED_INVEST")} - USDT 300</li>
                        <li>
                          {t("REFERAL_INCOME")} - 10% {t("PER_REFERAL")}
                        </li>
                        <li>
                          {t("LEVEL_BASED")} - {t("UP_TO")} 4% {t("DAILY")}
                        </li> */}
                        <li>
                          {t("FIXED_INVEST")} - USDT{" "}
                          {plandata && plandata[3] && plandata[3].planamount
                            ? plandata[3].planamount
                            : "-"}
                        </li>
                        <li>
                          {t("REFERAL_INCOME")} -{" "}
                          {plandata && plandata[3] && plandata[3].referralincome
                            ? plandata[3].referralincome
                            : "-"}
                          % {t("PER_REFERAL")}
                        </li>
                        <li>
                          {" "}
                          {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                          {plandata && plandata[3] && plandata[3].planintrest
                            ? plandata[3].planintrest
                            : "-"}
                          % {t("DAILY")}
                        </li>
                      </ul>
                      <div className="dashboard_platinum_wrap">
                        {/* <button class="btn secondary_btn mt-3" data-bs-toggle="modal" data-bs-target="#Standards1">{t('JOIN_NOW')}</button> */}
                        {/* {userstatus == true && (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-platinum"
                          >
                            Dashboard
                          </a>
                        )}
                        {userstatus == false && ( */}

                        {userstatus &&
                          userstatus[3] &&
                          userstatus[3].isExist == true ? (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-platinum"
                          >
                            Dashboard
                          </a>
                        ) : (
                          <button
                            class="btn secondary_btn mt-3"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Standards1"
                            onClick={() => handlePlan("3")}
                          >
                            {t("JOIN_NOW")}
                          </button>
                        )}
                        {/* )} */}
                      </div>
                      <div className="table-responsive">
                        <div className="standard_panel standard_panel_platinum">
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_one">
                                <h2>
                                  {plandata &&
                                    plandata[3] &&
                                    plandata[3]._percentageLimit
                                    ? plandata[3]._percentageLimit[4] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 4</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[3] &&
                                  plandata[3]._referCountsLimit
                                  ? plandata[3]._referCountsLimit[4]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[3] &&
                                    plandata[3]._referCountsLimit &&
                                    plandata[3]._referCountsLimit[4],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_two">
                                <h2>
                                  {plandata &&
                                    plandata[3] &&
                                    plandata[3]._percentageLimit
                                    ? plandata[3]._percentageLimit[3] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 3</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[3] &&
                                  plandata[3]._referCountsLimit
                                  ? plandata[3]._referCountsLimit[3]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[3] &&
                                    plandata[3]._referCountsLimit &&
                                    plandata[3]._referCountsLimit[3],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_three">
                                <h2>
                                  {plandata &&
                                    plandata[3] &&
                                    plandata[3]._percentageLimit
                                    ? plandata[3]._percentageLimit[2] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 2</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[3] &&
                                  plandata[3]._referCountsLimit
                                  ? plandata[3]._referCountsLimit[2]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[3] &&
                                    plandata[3]._referCountsLimit &&
                                    plandata[3]._referCountsLimit[2],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_four">
                                <h2>
                                  {plandata &&
                                    plandata[3] &&
                                    plandata[3]._percentageLimit
                                    ? plandata[3]._percentageLimit[1] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 1</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[3] &&
                                  plandata[3]._referCountsLimit
                                  ? plandata[3]._referCountsLimit[1]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[3] &&
                                    plandata[3]._referCountsLimit &&
                                    plandata[3]._referCountsLimit[1],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_five">
                                <h2>
                                  {plandata &&
                                    plandata[3] &&
                                    plandata[3]._percentageLimit
                                    ? plandata[3]._percentageLimit[0] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 0</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[3] &&
                                  plandata[3]._referCountsLimit
                                  ? plandata[3]._referCountsLimit[0]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[3] &&
                                    plandata[3]._referCountsLimit &&
                                    plandata[3]._referCountsLimit[0],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-diamond"
                    role="tabpanel"
                    aria-labelledby="pills-diamond-tab"
                  >
                    <div className="bronze_box">
                      <h2 className="h2tag">{t("DIAMOND")}</h2>
                      <ul className="std_details_list">
                        {/* <li>{t("FIXED_INVEST")} - USDT 550</li>
                        <li>
                          {t("REFERAL_INCOME")}- 10% {t("PER_REFERAL")}
                        </li>
                        <li>
                          {t("LEVEL_BASED")} - {t("UP_TO")} 4.5% {t("DAILY")}
                        </li> */}
                        <li>
                          {t("FIXED_INVEST")} - USDT{" "}
                          {plandata && plandata[4] && plandata[4].planamount
                            ? plandata[4].planamount
                            : "-"}
                        </li>
                        <li>
                          {t("REFERAL_INCOME")} -{" "}
                          {plandata && plandata[4] && plandata[4].referralincome
                            ? plandata[4].referralincome
                            : "-"}
                          % {t("PER_REFERAL")}
                        </li>
                        <li>
                          {" "}
                          {t("LEVEL_BASED")} - {t("UP_TO")}{" "}
                          {plandata && plandata[4] && plandata[4].planintrest
                            ? plandata[4].planintrest
                            : "-"}
                          % {t("DAILY")}
                        </li>
                      </ul>
                      <div className="dashboard_diamond_wrap">
                        {/* {userstatus == true && (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-diamond"
                          >
                            Dashboard
                          </a>
                        )}
                        {userstatus == false && ( */}

                        {userstatus &&
                          userstatus[4] &&
                          userstatus[4].isExist == true ? (
                          <a
                            class="btn secondary_btn mt-3"
                            href="/dashboard-diamond"
                          >
                            Dashboard
                          </a>
                        ) : (
                          <button
                            class="btn secondary_btn mt-3"
                            // data-bs-toggle="modal"
                            // data-bs-target="#Standards1"
                            onClick={() => handlePlan("4")}
                          >
                            {t("JOIN_NOW")}
                          </button>
                        )}
                        {/* )} */}
                      </div>
                      <div className="table-responsive">
                        <div className="standard_panel standard_panel_diamond">
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_one">
                                <h2>
                                  {plandata &&
                                    plandata[4] &&
                                    plandata[4]._percentageLimit
                                    ? plandata[4]._percentageLimit[4] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 4</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[4] &&
                                  plandata[4]._referCountsLimit
                                  ? plandata[4]._referCountsLimit[4]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[4] &&
                                    plandata[4]._referCountsLimit &&
                                    plandata[4]._referCountsLimit[4],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_two">
                                <h2>
                                  {plandata &&
                                    plandata[4] &&
                                    plandata[4]._percentageLimit
                                    ? plandata[4]._percentageLimit[3] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 3</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[4] &&
                                  plandata[4]._referCountsLimit
                                  ? plandata[4]._referCountsLimit[3]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[4] &&
                                    plandata[4]._referCountsLimit &&
                                    plandata[4]._referCountsLimit[3],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_three">
                                <h2>
                                  {plandata &&
                                    plandata[4] &&
                                    plandata[4]._percentageLimit
                                    ? plandata[4]._percentageLimit[2] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 2</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[4] &&
                                  plandata[4]._referCountsLimit
                                  ? plandata[4]._referCountsLimit[2]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[4] &&
                                    plandata[4]._referCountsLimit &&
                                    plandata[4]._referCountsLimit[2],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_four">
                                <h2>
                                  {plandata &&
                                    plandata[4] &&
                                    plandata[4]._percentageLimit
                                    ? plandata[4]._percentageLimit[1] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 1</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[4] &&
                                  plandata[4]._referCountsLimit
                                  ? plandata[4]._referCountsLimit[1]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[4] &&
                                    plandata[4]._referCountsLimit &&
                                    plandata[4]._referCountsLimit[1],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                          <div className="standard_row">
                            <div>
                              <div className="standard_column_five">
                                <h2>
                                  {plandata &&
                                    plandata[4] &&
                                    plandata[4]._percentageLimit
                                    ? plandata[4]._percentageLimit[0] / 100 +
                                    "%"
                                    : "-"}
                                </h2>
                                <p>Daily</p>
                              </div>
                            </div>
                            <div>
                              <h3>LEVEL 0</h3>
                              <p>
                                (Minimum{" "}
                                {plandata &&
                                  plandata[4] &&
                                  plandata[4]._referCountsLimit
                                  ? plandata[4]._referCountsLimit[0]
                                  : "-"}{" "}
                                Direct Referrals)
                              </p>
                            </div>
                            <div>
                              {Array.from(
                                {
                                  length:
                                    plandata &&
                                    plandata[4] &&
                                    plandata[4]._referCountsLimit &&
                                    plandata[4]._referCountsLimit[0],
                                },
                                (_, index) => (
                                  <i class="fas fa-user"></i>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Community Section */}
        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h3 className="inner_subtitle">{t("INTL_COMMUNITY")}</h3>
                <h2 className="inner_title">{t("GLOBAL")}</h2>
              </div>
              <div
                className="col-md- 10 col-lg-8 m-auto text-center mt-3 mb-4"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <p className="inner_para"> {t("MEMBER")}</p>
              </div>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="100"
              >
                <div className="community_box">
                  <img
                    src={require("../assets/images/icon_02.png")}
                    alt="Icon"
                    className="img-fluid"
                  />
                  <h2>{t("RISK")}</h2>
                  <p>{t("RISK_DESC")}</p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="500"
              >
                <div className="community_box">
                  <img
                    src={require("../assets/images/icon_01.png")}
                    alt="Icon"
                    className="img-fluid"
                  />
                  <h2>{t("SECURE")}</h2>
                  <p>{t("SECURE_DESC")}</p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="3000"
                data-aos-delay="900"
              >
                <div className="community_box">
                  <img
                    src={require("../assets/images/icon_03.png")}
                    alt="Icon"
                    className="img-fluid"
                  />
                  <h2>{t("RESTRICT")}</h2>
                  <p>{t("RESTRICT_DESC")}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Income Earned Section */}
        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title"> {t("EXPLAIN")}</h2>
                <div className="earn_inc_select_grp">
                  <div className="form-group">
                    <label>Choose Standard</label>
                    <select
                      className={parseInt(selectedplan) == 0 ? "form-select form_select_bronze" : parseInt(selectedplan) == 1 ? "form-select form_select_silver" : parseInt(selectedplan) == 2 ? "form-select form_select_gold" : parseInt(selectedplan) == 3 ? "form-select form_select_platinum" : parseInt(selectedplan) == 4 ? "form-select form_select_diamond" : "form-select"}
                      onChange={(e) => setselectedplan(e.target.value)}
                    >
                      <option value={0}>Bronze</option>
                      <option value={1}>Silver</option>
                      <option value={2}>Gold</option>
                      <option value={3}>Platinum</option>
                      <option value={4}>Diamond</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Choose Level</label>
                    <select
                      className={parseInt(selectedplan) == 0 ? "form-select form_select_bronze" : parseInt(selectedplan) == 1 ? "form-select form_select_silver" : parseInt(selectedplan) == 2 ? "form-select form_select_gold" : parseInt(selectedplan) == 3 ? "form-select form_select_platinum" : parseInt(selectedplan) == 4 ? "form-select form_select_diamond" : "form-select"}
                      onChange={(e) => setselectedlevel(e.target.value)}
                    >
                      <option value={0}>Level 0</option>
                      <option value={1}>Level 1</option>
                      <option value={2}>Level 2</option>
                      <option value={3}>Level 3</option>
                      <option value={4}>Level 4</option>
                    </select>
                  </div>
                </div>
                <p className="inner_para">
                  Earned income includes level income and referral income.
                </p>
                <h4>{t("EXPLAIN_TITLE")}</h4>
                <p className="inner_para">
                  {homeData?.planName} = {homeData?.planamount} USDT
                </p>
                {/* <p className="inner_para">
                  Referrals = {homeData?.referCountsLimit} Direct Referrals
                </p> */}
                <p className="inner_para">
                  Level {homeData.Level} = {homeData?.percentageLimit}% Daily
                  interest
                  {/* on {homeData?.planamount} USDT */}
                </p>
                <p className="inner_para">
                  Level Income =
                  {/* {homeData?.percentageLimit}% of{" "}
                  {homeData?.planamount} USDT ={" "} */}{" "}
                  <span className="large_txt">
                    {(homeData?.planamount * homeData?.percentageLimit) / 100}{" "}
                    USDT per day
                  </span>
                </p>
                <p className="inner_para">
                  The smart contract automatically terminates your contract with
                  the Elite Vision Network protocols for that standard, once
                  your total earned amount equals {homeData?.planamount * 2}{" "}
                  USDT (2X of your total investment of {homeData?.planamount}{" "}
                  USDT).
                </p>

                <p className="inner_para">
                  Your daily earnings are credited everyday into your dashboard
                  and claimable by you into your wallet via the blockchain smart
                  contract technology.
                </p>

                <h4 className="mt-5">Referral Based Income</h4>
                {/* <p className="inner_para">
                  <b>Referrals = Rewards (USDT)</b>
                </p> */}
                <p className="inner_para">
                  {homeData?.planName} ={homeData?.planamount} USDT
                </p>
                <p className="inner_para">
                  Direct Referrals = {homeData?.referCountsLimit}
                </p>
                {/* <p className="inner_para">
                  Level {homeData.Level} = {homeData.percentageLimit}% Daily
                  interest on {homeData?.planamount} USDT
                </p> */}
                <p className="inner_para">
                  Referral Reward = {homeData.referralincome}% per referral
                </p>
                <p className="inner_para">
                  Referral Income =
                  {/* ({homeData?.referralincome}% of{" "}
                  {homeData?.planamount} USDT ={" "}
                  {(homeData?.planamount *
                    parseFloat(homeData?.referralincome)) /
                    100}{" "}
                  USDT per referral) * {homeData?.referCountsLimit} Direct
                  Referrals ={" "} */}
                  <span className="large_txt">
                    {((homeData?.planamount *
                      parseFloat(homeData?.referralincome)) /
                      100) *
                      parseFloat(homeData?.referCountsLimit)}{" "}
                    USDT
                  </span>
                </p>
                <p className="inner_para">
                  Referral income is automatically added instantly into your
                  wallet via blockchain smart contract execution.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Platform Activity Section */}
        <section>
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title"> {t("RECENT_ACTIVITY")}</h2>
                {/* <p className="inner_para">
                  Lorem ipsum dolor sit amet, consectetur
                </p> */}
                {userdata && userdata.length == 0 && (
                  <p className="inner_para">No records</p>
                )}
                {userdata && userdata.length > 0 && (
                  <div className="recent_activity_panel_wrapper">
                    <div className="recent_activity_panel_inner">
                      <Scrollbars
                        style={{ width: "100%", height: 500 }}
                        autoHide
                      >
                        {userdata &&
                          userdata.length > 0 &&
                          userdata.map((item, key) => {
                            var concat = "";
                            if (item && item.userid != "") {
                              var addr = item.userid.substring(0, 14);
                              var addr1 = item.userid.substring(32, 42);
                              concat = addr + "...." + addr1;
                            }

                            return (
                              <div className="recent_activity_single">
                                <div className="recent_activity_left">
                                  <img
                                    src={require("../assets/images/icon_04.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                  <img
                                    src={
                                      item.type == "joinPlan"
                                        ? require("../assets/images/icon_05.png")
                                        : require("../assets/images/icon_06.png")
                                    }
                                    alt="Icon"
                                    className="img-fluid activity_icon"
                                  />
                                  <h4>
                                    {item.type == "joinPlan"
                                      ? "New user joined"
                                      : item.type == "claimRewards"
                                        ? "Claim"
                                        : "upgradePlan"}
                                  </h4>
                                  <h3
                                    className="recent_act_id"
                                    title={item.userid}
                                  >
                                    {concat}
                                  </h3>
                                </div>
                                <div className="recent_activity_right">
                                  <a
                                    href={config.txUrl + item.trx}
                                    target="_blank"
                                  >
                                    <img
                                      src={require("../assets/images/window_icon.png")}
                                      alt="Icon"
                                      className="img-fluid"
                                    />
                                  </a>
                                  <h5>
                                    {item.min > "60"
                                      ? item.hours + " hrs"
                                      : item.min + " mins"}
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                      </Scrollbars>

                      {/* <div className="recent_activity_single">
                        <div className="recent_activity_left">
                          <img
                            src={require("../assets/images/icon_04.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                          <img
                            src={require("../assets/images/icon_06.png")}
                            alt="Icon"
                            className="img-fluid activity_icon"
                          />
                          <h3 className="recent_act_id">ID 1210781</h3>
                          <h4>+40 USDT in x4</h4>
                        </div>
                        <div className="recent_activity_right">
                          <a href="#" target="_blank">
                            <img
                              src={require("../assets/images/window_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </a>
                          <h5>1 minute</h5>
                        </div>
                      </div> */}

                      {/* <div className="recent_activity_single">
                        <div className="recent_activity_left">
                          <img
                            src={require("../assets/images/icon_04.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                          <img
                            src={require("../assets/images/icon_06.png")}
                            alt="Icon"
                            className="img-fluid activity_icon"
                          />
                          <h3 className="recent_act_id">ID 1210781</h3>
                          <h4>+40 USDT in x4</h4>
                        </div>
                        <div className="recent_activity_right">
                          <a href="#" target="_blank">
                            <img
                              src={require("../assets/images/window_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </a>
                          <h5>1 minute</h5>
                        </div>
                      </div>

                      <div className="recent_activity_single">
                        <div className="recent_activity_left">
                          <img
                            src={require("../assets/images/icon_04.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                          <img
                            src={require("../assets/images/icon_06.png")}
                            alt="Icon"
                            className="img-fluid activity_icon"
                          />
                          <h3 className="recent_act_id">ID 1210781</h3>
                          <h4>+40 USDT in x4</h4>
                        </div>
                        <div className="recent_activity_right">
                          <a href="#" target="_blank">
                            <img
                              src={require("../assets/images/window_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </a>
                          <h5>1 minute</h5>
                        </div>
                      </div>

                      <div className="recent_activityconsole.log(item,'itemmmmm')
                            src={require("../assets/images/icon_05.png")}
                            alt="Icon"
                            className="img-fluid activity_icon"
                          />
                          <h4>New user joined</h4>
                          <h3 className="recent_act_id">ID 1210781</h3>
                        </div>
                        <div className="recent_activity_right">
                          <a href="#" target="_blank">
                            <img
                              src={require("../assets/images/window_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </a>
                          <h5>1 minute</h5>
                        </div>
                      </div>

                      <div className="recent_activity_single">
                        <div className="recent_activity_left">
                          <img
                            src={require("../assets/images/icon_04.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                          <img
                            src={require("../assets/images/icon_06.png")}
                            alt="Icon"
                            className="img-fluid activity_icon"
                          />
                          <h3 className="recent_act_id">ID 1210781</h3>
                          <h4>+40 USDT in x4</h4>
                        </div>
                        <div className="recent_activity_right">
                          <a href="#" target="_blank">
                            <img
                              src={require("../assets/images/window_icon.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                          </a>
                          <h5>1 minute</h5>
                        </div> </Scrollbars>
                      </div> */}
                    </div>
                    {/* <button className="primary_btn">
                    Load More
                    <img
                      src={require("../assets/images/load_more_icon.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                  </button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section className="faq_section">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-12 text-center"
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <h2 className="inner_title">FAQ</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div
                  className="faq_accordion"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item active">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Why is EVN using Smart Contracts?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            It automates the actions that would otherwise be
                            completed by the parties in the agreement. This
                            removes the need for both parties to trust each
                            other.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          What is the Minimum Investment Amount in EVN?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            {plandata?.[0]?.planamount} USDT is the entry level
                            standard corresponding to the Bronze category. Other
                            standards can be upgraded or accessed by clicking on
                            "Choose Standards" button above.
                          </p>
                          <p className="inner_para">
                            <b>No Hidden Costs</b>
                          </p>
                          <p className="inner_para">
                            One-time registration cost of 10% of investment
                            (limited time only) - Slots are filling up fast
                          </p>
                          <p className="inner_para">
                            From August 25, 2023 onwards, registration cost will
                            be 15% or more on investment.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          How much can I Earn on EVN?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            You can earn a maximum of <b>2X</b> of your total
                            investment upon which the smart contract algorithm
                            automatically terminates your ID.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Can I Invest more than once in EVN?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            You can join multiple standards simultaneously and
                            have different initial investments. However, you can
                            only invest once per wallet into each standard until
                            the algorithm terminates your ID for that particular
                            wallet.
                          </p>
                          <p className="inner_para">
                            <b>Example:</b>
                          </p>
                          <p className="inner_para">
                            User 1 with [ID 55] can join Bronze standard with{" "}
                            {plandata?.[0]?.planamount}
                            USDT investment and can also join Silver standard
                            with {plandata?.[1]?.planamount} USDT investment.
                            User 1 may choose to join other standards like Gold,
                            Platinum and Diamond as well with the same [ID 55]
                          </p>
                          <p className="inner_para">
                            The user 1 can choose to rejoin second time in the
                            bronze standard with the same [ID55] only after
                            reaching 2X earned income on the first investment in
                            the bronze standard.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          How can I Earn on EVN?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            You can earn via two methods simultaneously:
                          </p>
                          <p className="inner_para">
                            {" "}
                            1. Level Based Earnings (Earn a Daily % based on
                            standard & level)
                          </p>
                          <p className="inner_para">
                            2. Referral Based Earnings (Earn 10% per referral)
                          </p>
                          <p className="inner_para">
                            If {plandata?.[0]?.planamount} USDT is your Bronze
                            standard investment, your total earned income must
                            reach {plandata?.[0]?.planamount} USDT before your
                            contract on EVN is automatically terminated.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          What are the Standards?
                        </button>
                      </h2>
                      <div
                        id="collapseSix"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            Different Standards refer to different initial
                            investment amounts you can enter with.
                          </p>
                          <p className="inner_para">
                            Bronze Standard is {plandata?.[0]?.planamount} USDT
                            <br />
                            Silver Standard is {plandata?.[1]?.planamount} USDT
                            <br />
                            and so on. (Refer to the Package Standards for more
                            info)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          What are the Levels?
                        </button>
                      </h2>
                      <div
                        id="collapseSeven"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            Higher levels refer to a higher daily percentage
                            income depending on your standard. (Refer to the
                            Package Standards for more info)
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          What is the maximum earnings on EVN?
                        </button>
                      </h2>
                      <div
                        id="collapseEight"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            You can earn a maximum of 2X of your total
                            investment upon which the smart contract algorithm
                            automatically terminates your ID.
                          </p>
                          <p className="inner_para">
                            Daily earnings are claimed via blockchain smart
                            contract technology.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapsenine"
                          aria-expanded="false"
                          aria-controls="collapsenine"
                        >
                          How is EVN able to offer high interest rates?
                        </button>
                      </h2>
                      <div
                        id="collapsenine"
                        className="accordion-collapse collapse"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p className="inner_para">
                            An elite network of individuals have created a fund
                            disbursing blockchain mechanism. They are offering
                            knowledge based documents on the benefits of Digital
                            Assets that is accessible once you join the network.
                            The benefits of making the world move to a
                            decentralized financial system far outweigh the
                            monetary costs in acquiring a network of users with
                            vision beyond the ordinary.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Joinnowmodal planid={planid} address={refferal} planname={planname} />
        <Footer />
      </div>
    </div>
  );
}
