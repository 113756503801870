
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import config from "../config/config";
import {connection} from "../helper/connection"
import ABI from "../ABI/Abi.json"
import { useSelector,useDispatch } from 'react-redux';
import Web3 from "web3";
const Mystandard = (props) => {
  const { t, i18n } = useTranslation();

  const [active, setactive] = useState("");
  const [path, setpath] = useState("");
  const walletConnection = useSelector((state) => state.walletConnection);
  const [plandata, setplandata] = useState([]);
   function handleactive(value,link) {
    localStorage.setItem("active",value)
    window.location.href='/'+link
  }
  useEffect(() => {
    if(localStorage && localStorage.getItem("active")!='')
    var data = localStorage.getItem("active")
    setactive(data)
  },[]);
  function loadScript() {
    console.log(window.location.pathname,'hrefff')
    setpath(window.location.pathname)
  }
  useEffect(() => {
    loadScript();
    getplanData();
  }, []);
  async function getplanData() {
    try{
      // var WEB3 = await connection();
      var WEB3= new Web3(config.netWorkUrl);
   
        var contractAddress = config.contractAddress
        var web3 = WEB3;
        var Contract = new web3.eth.Contract(ABI,contractAddress);
var palnlevel = ['0','1','2','3','4']
var planarr=[]
for (var i in palnlevel){
  var planinfo= await Contract.methods.getPoolDetails(palnlevel[i]).call();
  console.log(planinfo[0],'planinfo')
  var planobj={}
    planobj.planid=planinfo[0];
    planobj.planamount=planinfo[1]/10**18;
    planobj.referralincome=planinfo[2];
    planobj.planintrest=(planinfo._percentageLimit[4])/100
    planarr.push(planobj)
}
console.log(planarr,'userarr44')
setplandata(planarr)
      
    }
    catch(err){
console.log(err,'errr')
    }
  }
  // useEffect(() => {
  //   getplanData();
  // }, [walletConnection.address])
  return (
    <div
    class="modal fade standard_popup"
    id="Standards"
    tabindex="-1"
    aria-labelledby="StandardsLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="StandardsLabel">
           {t('STANDARDS')}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        <div class="modal-body">
          <div className="box_flx">
            <a className={path=='/standard-bronze'?'box selected':'box' } href='/standard-bronze'>              
              <p> {t('BRONZE')}</p>
              <div className="round bronze"></div>
              <small>{plandata && plandata[0] &&  plandata[0].planamount? plandata[0].planamount:'-'} USDT</small>
            </a>
            <a className={path=='/standard-silver'?'box selected':'box'} href='/standard-silver'>              
              <p>{t('SILVER')}</p>
              <div className="round silver"></div>
              <small>{plandata && plandata[1] &&  plandata[1].planamount? plandata[1].planamount:'-'} USDT</small>
            </a>
            <a className={path=='/standard-gold'?'box selected':'box'} href='/standard-gold' >              
              <p>{t('GOLD')}</p>
              <div className="round gold"></div>
              <small>{plandata && plandata[2] &&  plandata[2].planamount? plandata[2].planamount:'-'} USDT</small>
            </a>
            <a className={path=='/standard-platinum'?'box selected':'box'} href='/standard-platinum'  >              
              <p>{t('PLATINUM')}</p>
              <div className="round platinum"></div>
              <small>{plandata && plandata[3] &&  plandata[3].planamount? plandata[3].planamount:'-'} USDT</small>
            </a>
            <a className={path=='/standard-diamond'?'box selected':'box'} href='/standard-diamond' >              
              <p>{t('DIAMOND')}</p>
              <div className="round diamond"></div>
              <small>{plandata && plandata[4] &&  plandata[4].planamount? plandata[4].planamount:'-'} USDT</small>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default Mystandard;