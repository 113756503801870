
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector,useDispatch } from 'react-redux';
import {connection} from "../helper/connection"
import config from "../config/config"
import ABI from "../ABI/Abi.json"
import { toastAlert } from "../helper/toastAlert";
import TokenAbi from "../ABI/TokenAbi.json"
import {convert}from "../helper/convert"
const UpgradeLevel = (props) => {
  const {settxhash1,txhash1} = props
  const { t, i18n } = useTranslation();
  const [userdata, setuserdata] = useState([]);
  const [select, setselect] = useState('5');
  const [upgbtn, setupgbtn]= useState(false);
  const [hash, sethash]= useState(false);
  const [txhash, settxhash]= useState('');
  const [complete, setcomplete]= useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);
  console.log(walletConnection.address,'walletConnection')

  async function getuserInfo() {
try{
  var WEB3 = await connection();
  if(WEB3 && WEB3.address!=''){
    var contractAddress = config.contractAddress
    var web3 = WEB3.web3;
    var Contract = new web3.eth.Contract(ABI,contractAddress);
    var currAddr = WEB3.address;
    var palnlevel = ['0','1','2','3','4']
    var userarr=[]
    for (var i in palnlevel ){
      var pid=palnlevel[i]
      var userplan= await Contract.methods.userInfo(pid,currAddr).call();
      console.log(userplan,'userpan')
      var obj={}
      obj.paln=palnlevel[i];
      obj.isExist=userplan.isExist
      userarr.push(obj)
    }
    console.log(userarr,'userarr')
    setuserdata(userarr)
    if(userarr && userarr[0] && userarr[1]&& userarr[2]&& userarr[3]&& userarr[4]&& userarr[0].isExist==true &&userarr[1].isExist==true && userarr[2].isExist==true && userarr[3].isExist==true &&userarr[4].isExist==true){
      setcomplete(true)
    }
  }
}
catch(err){

}
  }

  function handlePlan(planid) {
    setselect(planid)

   
  }

  const handleUpgrade = async () => {
    try{
      setupgbtn(true)
      if(select=='5'){
        toastAlert('error', "Select any plan", 'invalid');  
        setupgbtn(false) 
        return
      }
      

      var WEB3 = await connection();
      if(WEB3 && WEB3.address!=''){
        var contractAddress = config.contractAddress
        var web3 = WEB3.web3;

       

        var Contract = new web3.eth.Contract(ABI,contractAddress);
        var currAddr = WEB3.address;

        var pid=select
        var poolInfo= await Contract.methods.poolInfo(pid).call();
        console.log(poolInfo,'poolInfo')
        var planamount= poolInfo && poolInfo.poolPrize && poolInfo.poolPrize 
        console.log(planamount,'planamount')
     
        var tokenAddress = config.tokenAddress
        let tokenContract = new web3.eth.Contract(TokenAbi, tokenAddress);
        console.log(tokenContract,'tokenContract')
        var allowance = await tokenContract.methods.allowance(currAddr,contractAddress).call()
        console.log(allowance,'allowance')
      
        var allowamt = allowance/1000000000000000000
    
        var approveAmt = 100000*10**18;
        var amt = await convert(approveAmt)

  

        var getBalance = await web3.eth.getBalance(currAddr);
        var bal = getBalance / 10 ** 18;

        if(bal<=0){
          toastAlert('error', "Insufficient Gas fee", 'invalid');
          setupgbtn(false)
          return
        }
        let contract = new web3.eth.Contract(TokenAbi,config.tokenAddress);
        let tokenBalance = await contract.methods.balanceOf(currAddr).call();
       
        const tokenDecimal = await contract.methods.decimals().call();
      
        var allowablebalance = tokenBalance/(10 ** parseFloat(tokenDecimal))
        console.log(allowablebalance,'allowablebalance95',typeof(allowablebalance))
        var divideamt = planamount/1000000000000000000
      if(allowablebalance < divideamt){
        console.log(allowablebalance,'allowablebalance95222')
        toastAlert('error', "Failed to upgrade due to insufficient balance", 'invalid');
        setupgbtn(false) 
        return
      }


        console.log(allowamt,planamount,'allowamt112')
        if(allowamt < divideamt ){
          var result1 = await tokenContract.methods.approve(
            contractAddress,
            amt.toString()
          ).send({ from: currAddr });
          console.log(result1,'result1')
        
          if( result1 && result1.events && result1.events.Approval && result1.events.Approval.returnValues && result1.events.Approval.returnValues.value ){
      var checkamt = result1.events.Approval.returnValues.value/1000000000000000000
      console.log(checkamt,divideamt,'planamount118')
            if(checkamt<divideamt){
      
              toastAlert('error', "Approve amount is too low", 'invalid');
             
              return;
            }
          }
          }


        var estimategas = await Contract.methods.upgradePlan(pid,planamount.toString()).estimateGas({from: currAddr});
        var gas = estimategas+100000;
        gas = gas/10**8;

        console.log(gas,'estimategas',bal)
        if(gas>bal){
          toastAlert('error', "Insufficient Gas fee", 'invalid');  
          setupgbtn(false) 
          return
        }
        
        var planupgrade = await Contract.methods.upgradePlan(pid,planamount.toString()).send({from: currAddr});
        console.log(planupgrade,'upgradelevel')

          if(planupgrade && planupgrade.status==true){
            window.$('#upgrade_level_modal').modal('hide');
            toastAlert('success', "Upgrade Success", 'invalid'); 
            window.$('#view_trans_modal').modal('show');
            settxhash1(planupgrade.transactionHash)
            sethash(true)
            setupgbtn(false)
            setTimeout(function () { 

              
              window.location.reload()
            },2000)
          }
      }
    }
    catch(err){
      console.log(err,'errrrrrrr')
      setupgbtn(false) 
      toastAlert('error', "Failed to upgrade", 'invalid');  
    }
  }
useEffect(() => {
  getuserInfo();
  }, [walletConnection.address,txhash1]);
  return (
    <div
    class="modal fade standard_popup"
    id="upgrade_level_modal"
    tabindex="-1"
    aria-labelledby="StandardsLabel"
    aria-hidden="true"
  >
    {console.log(complete,'complete')}
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="StandardsLabel">
         Standard Upgrade
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img
              src={require("../assets/images/close_icon.png")}
              alt="Image"
              className="img-fluid"
            />
          </button>
        </div>
        <div class="modal-body">
          <div className="box_flx">

            {console.log(select,'selectselect') }

         {userdata && userdata[0] && userdata[0].isExist==false &&
         
         <a className={select== 0 ? 'box selected':'box'} onClick={() => { handlePlan("0") }}
           >
              <div className="round bronze"></div>
              <p> {t('BRONZE')}</p>
            </a> 
         }   
            {userdata && userdata[1]  && userdata[1].isExist==false &&
            
            <a className={select== 1 ? 'box selected':'box'}  onClick={() => { handlePlan("1") }} >
            <div className="round silver"></div>
            <p>{t('SILVER')}</p>
          </a>
            }
          {userdata && userdata[2]  && userdata[2].isExist==false &&
          
          <a className={select== 2 ? 'box selected':'box'} onClick={() => { handlePlan("2") }} >
          <div className="round gold"></div>
          <p>{t('GOLD')}</p>
        </a>
          }
             {userdata && userdata[3]  && userdata[3].isExist==false &&
          
            <a className={select== 3 ? 'box selected':'box'}onClick={() => { handlePlan("3") }}>
              <div className="round platinum"></div>
              <p>{t('PLATINUM')}</p>
            </a>
}

{userdata && userdata[4]  && userdata[4].isExist==false &&
            <a className={select== 4 ? 'box selected':'box'} onClick={() => { handlePlan("4") }}>
              <div className="round diamond"></div>
              <p>{t('DIAMOND')}</p>
            </a>
}
          </div>
          {
            complete == false ? <button className="btn primary_btn p-3 py-2" onClick={() => handleUpgrade()}>{upgbtn && <i class="fas fa-spinner fa-spin"></i>} {t('UPGRADE')}</button>:<p className="text-center">All Standards Upgraded</p>
          }
          
        </div>
      </div>
    </div>
  
  </div>
  
  )
  
}

export default UpgradeLevel;