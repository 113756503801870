import React, { useEffect, useState } from "react";
import Footer from "./Footer.js";
import Navbar from "./Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import { toastAlert } from "../helper/toastAlert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { connection } from "../helper/connection";
import ABI from "../ABI/Abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getuserDetailsAction } from "../helper/multicall";
import config from "../config/config";
import ViewTrans from "./viewTrans.js";
import UpgradeLevel from "./Upgradelvel.js";
import { checkuser } from "../helper/connection";
import { Link, NavLink } from "react-router-dom";
import Web3 from "web3";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const Levelstandards = (props) => {
  const { t, i18n } = useTranslation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userstatus, setuserstatus] = useState([]);
  const [plandata, setplandata] = useState([]);

  useEffect(() => {
    AOS.init();

    getplanData();
  }, []);
  async function getplanData() {
    try{
      // var WEB3 = await connection();
      // if(WEB3 && WEB3.address!=''){
        var WEB3= new Web3(config.netWorkUrl);
        var contractAddress = config.contractAddress
        var web3 = WEB3;
        var Contract = new web3.eth.Contract(ABI,contractAddress);
var palnlevel = ['0','1','2','3','4']
var planarr=[]
for (var i in palnlevel){
  var planinfo= await Contract.methods.getPoolDetails(palnlevel[i]).call();
  console.log(planinfo[0],'planinfo')
  var planobj={}
    planobj.planid=planinfo[0];
    planobj.planamount=planinfo[1]/10**18;
    planobj.referralincome=planinfo[2];
    planobj.planintrest=(planinfo._percentageLimit[4])/100
    planarr.push(planobj)
}
console.log(planarr,'userarr232')
setplandata(planarr)
     // }
    }
    catch(err){
console.log(err,'errr')
    }
  }

  // useEffect(() => {
  
  //   getplanData();
  //   }, [walletConnection.address]);

  return (
  <>
         <h2 className="inner_title text-center"> Choose Standards</h2>
          <ul
            class="nav nav-pills package_tab_std"
            id="pills-tab"
            role="tablist"
          >
            <li class="nav-item bronze_tabbtn" role="presentation">
              <NavLink class="nav-link active" to="/standard-bronze">
                <span>Bronze - {plandata && plandata[0] &&  plandata[0].planamount? plandata[0].planamount:'-'} USDT</span>
              </NavLink>
            </li>
            <li class="nav-item silver_tabbtn" role="presentation">
              <NavLink class="nav-link" to="/standard-silver">
                <span>Silver - {plandata && plandata[1] &&  plandata[1].planamount? plandata[1].planamount:'-'} USDT</span>
              </NavLink>
            </li>
            <li class="nav-item gold_tabbtn" role="presentation">
              <NavLink class="nav-link" to="/standard-gold">
                <span>Gold - {plandata && plandata[2] &&  plandata[2].planamount? plandata[2].planamount:'-'} USDT</span>
              </NavLink>
            </li>
            <li class="nav-item platinum_tabbtn" role="presentation">
              <NavLink class="nav-link" to="/standard-platinum">
                <span>Platinum - {plandata && plandata[3] &&  plandata[3].planamount? plandata[3].planamount:'-'} USDT</span>
              </NavLink>
            </li>
            <li class="nav-item diamond_tabbtn" role="presentation">
              <NavLink class="nav-link" to="/standard-diamond">
                <span>Diamond - {plandata && plandata[4] &&  plandata[4].planamount? plandata[4].planamount:'-'} USDT</span>
              </NavLink>
            </li>
          </ul>    
            </> 
  );
}
export default Levelstandards;