import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import { toastAlert } from "../helper/toastAlert";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector, useDispatch } from "react-redux";
import { connection } from "../helper/connection";
import ABI from "../ABI/Abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { getuserDetailsAction } from "../helper/multicall";
import config from "../config/config";
import ViewTrans from "../components/viewTrans.js";
import UpgradeLevel from "../components/Upgradelvel.js";
import { checkuser } from "../helper/connection";
import { Link, NavLink } from "react-router-dom";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const Choosestandards = (props) => {
  const { t, i18n } = useTranslation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userstatus, setuserstatus] = useState([]);
  const [plandata, setplandata] = useState([]);
  const [path, setpath] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);
  async function getplanData() {
    try {
      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);
        var palnlevel = ["0", "1", "2", "3", "4"];
        var planarr = [];
        for (var i in palnlevel) {
          var planinfo = await Contract.methods
            .getPoolDetails(palnlevel[i])
            .call();
          console.log(planinfo[0], "planinfo");
          var planobj = {};
          planobj.planid = planinfo[0];
          planobj.planamount = planinfo[1] / 10 ** 18;
          planobj.referralincome = planinfo[2];
          planobj.planintrest = planinfo._percentageLimit[4] / 100;
          planarr.push(planobj);
        }
        console.log(planarr, "userarr232");
        setplandata(planarr);
      }
    } catch (err) {
      console.log(err, "errr");
    }
  }
  async function getuserInfo() {
    try {
      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(ABI, contractAddress);
        var currAddr = WEB3.address;
        var palnlevel = ["0", "1", "2", "3", "4"];
        var userarr = [];
        for (var i in palnlevel) {
          var pid = palnlevel[i];
          var userplan = await Contract.methods.userInfo(pid, currAddr).call();
          console.log(userplan, "userpan");
          var obj = {};
          obj.paln = palnlevel[i];
          obj.isExist = userplan.isExist;
          userarr.push(obj);
        }
        console.log(userarr, "1876serw");
        setuserstatus(userarr);
      }
    } catch (err) {}
  }
  useEffect(() => {
    getuserInfo();
    getplanData();
    console.log(window.location.pathname,'hrefff911')
    setpath(window.location.pathname)
  }, [walletConnection.address]);

  return (
    <>
      {/* <h2 className="inner_title text-center"> Choose Dashboards</h2> */}
      <ul
        class="nav nav-pills mb-3 package_tab package_tab_fullwidth package_dashboard"
        id="pills-tab"
        role="tablist"
      >
        <li class="nav-item" role="presentation">
          <Link
            class={
              userstatus && userstatus[0] && userstatus[0].isExist == true
                ? "nav-link active bronze_tabbtn"
                : "nav-link bronze_tabbtn dash_link_diasbled"
            }
            to="/dashboard-bronze"
          >
            Bronze -{" "}
            {plandata && plandata[0] && plandata[0].planamount
              ? plandata[0].planamount
              : "-"}{" "}
            USDT
          </Link>
          {userstatus && userstatus[0] && userstatus[0].isExist == true && (
            <NavLink to="/dashboard-bronze" className="select_link">
              
              {path=='/dashboard-bronze'?'Current Dashboard':'Select' }
            </NavLink>
          )}
          {userstatus && userstatus[0] && userstatus[0].isExist == false && (
            <Link to="/standard-bronze" className="join_now_link">
              Join Now
            </Link>
          )}
        </li>
        <li class="nav-item" role="presentation">
          <Link
            class={
              userstatus && userstatus[1] && userstatus[1].isExist == true
                ? "nav-link silver_tabbtn"
                : "nav-link silver_tabbtn dash_link_diasbled"
            }
            to="/dashboard-silver"
          >
            Silver -{" "}
            {plandata && plandata[1] && plandata[1].planamount
              ? plandata[1].planamount
              : "-"}{" "}
            USDT
          </Link>
          {userstatus && userstatus[1] && userstatus[1].isExist == true && (
            <NavLink to="/dashboard-silver" className="select_link">
               {path=='/dashboard-silver'?'Current Dashboard':'Select' }
            </NavLink>
          )}
          {userstatus && userstatus[1] && userstatus[1].isExist == false && (
            <Link to="/standard-silver" className="join_now_link">
              Join Now
            </Link>
          )}
        </li>
        <li class="nav-item" role="presentation">
          <Link
            class={
              userstatus && userstatus[2] && userstatus[2].isExist == true
                ? "nav-link gold_tabbtn"
                : "nav-link gold_tabbtn dash_link_diasbled"
            }
            to="/dashboard-gold"
          >
            Gold -{" "}
            {plandata && plandata[2] && plandata[2].planamount
              ? plandata[2].planamount
              : "-"}{" "}
            USDT
          </Link>
          {userstatus && userstatus[2] && userstatus[2].isExist == true && (
            <NavLink to="/dashboard-gold" className="select_link">
              {path=='/dashboard-gold'?'Current Dashboard':'Select' }
            </NavLink>
          )}
          {userstatus && userstatus[2] && userstatus[2].isExist == false && (
            <Link to="/standard-gold" className="join_now_link">
              Join Now
            </Link>
          )}
        </li>
        <li class="nav-item" role="presentation">
          <Link
            class={
              userstatus && userstatus[3] && userstatus[3].isExist == true
                ? "nav-link platinum_tabbtn"
                : "nav-link platinum_tabbtn dash_link_diasbled"
            }
            to="/dashboard-platinum"
          >
            Platinum -{" "}
            {plandata && plandata[3] && plandata[3].planamount
              ? plandata[3].planamount
              : "-"}{" "}
            USDT
          </Link>
          {userstatus && userstatus[3] && userstatus[3].isExist == true && (
            <NavLink to="/dashboard-platinum" className="select_link">
              {path=='/dashboard-platinum'?'Current Dashboard':'Select' }
            </NavLink>
          )}
          {userstatus && userstatus[3] && userstatus[3].isExist == false && (
            <Link to="/standard-platinum" className="join_now_link">
              Join Now
            </Link>
          )}
        </li>
        <li class="nav-item" role="presentation">
          <Link
            class={
              userstatus && userstatus[4] && userstatus[4].isExist == true
                ? "nav-link diamond_tabbtn"
                : "nav-link diamond_tabbtn dash_link_diasbled"
            }
            to="/dashboard-diamond"
          >
            Diamond -{" "}
            {plandata && plandata[4] && plandata[4].planamount
              ? plandata[4].planamount
              : "-"}{" "}
            USDT
          </Link>
          {userstatus && userstatus[4] && userstatus[4].isExist == true && (
            <NavLink to="/dashboard-diamond" className="select_link">
              {path=='/dashboard-diamond'?'Current Dashboard':'Select' }
            </NavLink>
          )}
          {userstatus && userstatus[4] && userstatus[4].isExist == false && (
            <Link to="/standard-diamond" className="join_now_link">
              Join Now
            </Link>
          )}
        </li>
      </ul>
    </>
  );
};
export default Choosestandards;
