import React from "react";
import { useTranslation } from "react-i18next";
import config from'../config/config'
export default function Footer(props) {
  const { t, i18n } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div> 
            <h5>{t('SMART_CONTRACT')}:</h5>
            <a href={`${config.txUrlAddress}${config.contractAddress}`} target="_blank" className="smartconatract_link">
              {config && config.contractAddress && config.contractAddress}
            </a>
          </div>
          <p>
          {t('COPYRIGHT')}   © {new Date().getFullYear()}{" "}
            <a href="#">Elite Vision Network</a>,{t('ALL_RIGHTS')}
          </p>
        </div>
      </div>
    </footer>
  );
}
