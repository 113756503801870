import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function toastAlert(errorType, message, id,position = 'TOP_RIGHT' ) {
    console.log(errorType,message,'message')
    if (errorType == 'error') {

        toast.error(message, {
            autoClose: 4000,
            toastId: id,
            position: toast.POSITION[position],
        });

    } else if (errorType == 'success') {
        console.log("inside")
        toast.success(message, {
            autoClose: 4000,
            toastId: id,
            position: toast.POSITION[position],
        });
    }
}