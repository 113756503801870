export default {
    "STANDARDS": "Choose Standards",
    "CONNECT_WALLET" :"Connect Wallet",
    "MY_WALLET":"My Wallet",
    "BRONZE":"Bronze",
    "SILVER":"Silver",
    "GOLD":"Gold",
    "PLATINUM":"Platinum",
    "DIAMOND":"Diamond",
    "METAMASK":"Metamask",
    "WALLET_CONNECT":"Wallet Connect",
    "HOME_TITLE":"Elite Vision Network",
    "TITLE_DESC":"WHAT IS ELITE VISION NETWORK (EVN)?",
    "LEARN_MORE":"Learn More",
    "EARN":"Earn 1.5% on your Investment Daily",
    "CONTENT_1":"A decentralized networking platform that runs on Binance Smart Chain Blockchain (BSC). This Referral Marketing runs automatically without human intervention through the usage of blockchain smart contracts. The purpose of this Elite Vision Network is to promote awareness of the usage of technological innovations to compound wealth securely.",
    "CONTENT_2":"Wealth is earned in USDT which is redeemable 1:1 for US dollars at all eligible exchanges and stores.",
    "INTL_COMMUNITY":"International Community",
    "GLOBAL":"Global Business",
    "RISK":"Risk-Free Investment",
    "RISK_DESC":"Your investment is 100% secure via cryptography of blockchain technology. Elite Vision Network is a highly secure platform offering multi-layer security protection with decentralized protocols. All transactions are transparent on the BSC (Binance Smart Chain) blockchain.",
    "SECURE":"Multiply Your Income",
    "SECURE_DESC":"Referral-based earning has the power to boost your income potential.  As your network grows and referrals multiply, so does your income, providing you with an additional revenue stream that can make a real difference in your financial life.",
    "RESTRICT":"Special Access Entry",
    "RESTRICT_DESC" :"Fortunate are those in the elite circles of a visionary technological concept that promotes collective network growth of blockchain based decentralized systems through referral marketing.",
    "MEMBER":"To be eligible for high earnings via elite vision network, please request a referral ID from those who have recommended this limited opportunity. You will earn along with everyone in the network through this cutting edge innovation in blockchain systems that is promoting Blockchain Education.",
    "EXPLAIN":"Explanation of Earned Income",
    "EXPLAIN_TITLE":" Level Based Income",
    "EXPLAIN_DESC":" It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    "EXPLAIN_DESC_1":"It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
    "SMART_CONTRACT":"Smart-contract address",
    "FIXED_INVEST":"Fixed Investment",
    "REFERAL_INCOME":"Referral Income",
    "LEVEL_BASED":"Level Based Interest",
    "LEVEL_ZERO":"Level 0 - ( Zero Referrals) = 1.5% Daily",
    "BRONZE_L1":"Level 1 - (Minimum 1 Direct Referral) = 1.8% Daily",
    "BRONZE_L2":"Level 2 - (Minimum 3 Direct Referrals) = 2% Daily",
    "BRONZE_L3":"Level 3 - (Minimum 5 Direct Referrals) = 2.2% Daily",
    "BRONZE_L4":"Level 4 - (Minimum 8 Direct Referrals) = 2.5% Daily",
    "SILVER_L1":"Level 1 - (Minimum 2 Direct Referral) = 1.8% Daily",
    "SILVER_L2":"Level 2 -(Minimum 5 Direct Referrals) = 2% Daily",
    "SILVER_L3":"Level 3 - (Minimum 7 Direct Referrals) = 2.2% Daily",
    "SILVER_L4":"Level 4 - (Minimum 10 Direct Referrals) = 3% Daily",
    "GOLD_LEVEL0":"Level 0 - (Zero Referrals) = 1% Daily",
    "GOLD_L1":"Level 1 - (Minimum 3 Direct Referral) = 2% Daily",
    "GOLD_L2":"Level 2 - (Minimum 7 Direct Referrals) = 2.5% Daily",
    "GOLD_L3":"Level 3 - (Minimum 9 Direct Referrals) = 3% Daily",
    "GOLD_L4":"Level 4 - (Minimum 10 Direct Referrals) = 3.5% Daily",
    "PLATINUM_LEVEL0":"Level 0 - (Zero Referrals) = 1% Daily",
    "PLATINUM_L1":"Level 1 - (Minimum 3 Direct Referral) = 2.5% Daily",
    "PLATINUM_L2":"Level 2 - (Minimum 7 Direct Referrals) = 3% Daily",
    "PLATINUM_L3":"Level 3 - (Minimum 10 Direct Referrals) = 3.5% Daily",
    "PLATINUM_L4":"Level 4 - (Minimum 12 Direct Referrals) = 4% Daily",
    "DIAMOND_LEVEL0":"Level 0 - (Zero Referrals) = 0.5% Daily",
    "DIAMOND_L1":"Level 1 - (Minimum 2 Direct Referral) = 2.5% Daily",
    "DIAMOND_L2":"Level 2 - (Minimum 5 Direct Referrals) = 3% Daily",
    "DIAMOND_L3":"Level 3 - (Minimum 7 Direct Referrals) = 3.5% Daily",
    "DIAMOND_L4":"Level 4 - (Minimum 10 Direct Referrals) = 4.5% Daily",
    "DIRECT":"Direct",
    "REFERAL":"Referrals",
    "TOTAL":"TOTAL",
    "INVESTMENT":"INVESTMENT",
    "EARNED_INCOME":"EARNED INCOME",
    "EARNED_REFERAL":"Earned Referral",
    "INCOME":"Income",
    "EARNED_DAILY":"Earned Daily",
    "CLAIM":"Claim",
    "CLAIMABLE":"Claimable",
    "FUNDS":"Funds",
    "UPGRADE":"Upgrade",
    "MY_ID":"MY ID",
    "EARN_MORE":"Share Referral ID",
    "PER_REFERAL":"per referral",
    "DAILY":"Daily",
    "UP_TO":"up to",
    "LEVEL":"Level",
    "PROGRESS":"Progress towards earned income reaching 2X of your Total Investment",
    "COPYRIGHT":"Copyright",
    "ALL_RIGHTS":"All rights Reserved",
    "ADDRESS":"Address",
    "BALANCE":"Balance",
    "DISCONNECT":"Disconnect",
    "JOIN_NOW":"JOIN NOW",
    "REFERAL_ID":"Referral ID",
    "SUBMIT":"Submit",
    "RECENT_ACTIVITY":"Platform Recent Activity"
}