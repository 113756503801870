import Web3 from "web3";
import config from "../config/config";


import store from '../store';
import ABI from "../ABI/Abi.json"


// export async function connection() {
//   var currentProvider = store.getState()

//   var connect = {
//     web3: "",
//     address: "",
//     network: 0,
//     provider: "",
//   };

//   var provider = (currentProvider && currentProvider.walletConnection &&
//     currentProvider.walletConnection.provider
//     && currentProvider.walletConnection.provider !== "") ? currentProvider.walletConnection.provider : "";

//   var isConnected = "no"
//   var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("WEB3_CONNECT_CACHED_PROVIDER")
//   if (WEB3_CONNECT_CACHED_PROVIDER) {
//     var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
//     if (connnector === "injected" || connnector === "walletconnect"
//       || connnector === "walletlink" || connnector === "binancechainwallet") {
//       isConnected = "yes";
//     }
//   }

//   if (!isLoad && provider === "" && isConnected && web3Modal.cachedProvider) {
//     isLoad = true;
//     provider = await web3Modal.connect();
//   }

//   if (provider && provider !== "") {
//     //var provider = await web3Modal.connect();
//     var web3 = new Web3(provider);
//     if (typeof web3 !== "undefined") {

//       var network = await web3.eth.net.getId();
//       var result = await web3.eth.getAccounts();

//       var currAddr = result[0];
      
//       if (currAddr === undefined) currAddr = "";
//       if (network === config.NetworkId) {
//         connect.network = network;
//         connect.web3 = web3;
//         connect.address = currAddr;
//         connect.provider = provider;
//         connect.connect = "yes";
//       }
//     }
//   }
 
//   return connect;
// }

export async function checkuser(data) {
console.log(data,'ddddddddddddd')
var WEB3 = await connection();
console.log(WEB3,'WEB3zzzzz')
if(WEB3 && WEB3.web3){
  var contractAddress = config.contractAddress
  var web3 = WEB3.web3;
  var Contract = new web3.eth.Contract(ABI,contractAddress);
 console.log(Contract,'Contract')
 var result = await Contract.methods.userInfo(data.planid,WEB3.address).call()
 if(result){
  var userdata = {
    exist:result.isExist
     }
     return userdata
 }

}
// var userdata = {
//       exist:true
//        }
//        return userdata
}


export async function connection() {

  var currentProvider = store.getState()


  var connect = {
      web3: "",
      address: "",
      network: 0,
      provider: "",
      isChange: "false"
  };

  var provider = (currentProvider && currentProvider.walletConnection &&
      currentProvider.walletConnection.provider
      && currentProvider.walletConnection.provider !== "") ? currentProvider.walletConnection.provider : "";

  var isConnected = "no"
  var WEB3_CONNECT_CACHED_PROVIDER = localStorage.getItem("wagmi.wallet")
  if (WEB3_CONNECT_CACHED_PROVIDER) {
   
      var connnector = JSON.parse(WEB3_CONNECT_CACHED_PROVIDER)
      if (connnector === "metaMask" || connnector === "walletConnect") {
          isConnected = "yes";
      }
  }

  if (isConnected && provider && provider !== "" && currentProvider && currentProvider.walletConnection &&
      currentProvider.walletConnection.provider)
       {
      provider = currentProvider.walletConnection.provider
      

      
      var web3 = new Web3(provider);
      console.log(web3,'wwwwwwwwwwwwwwwww')
      if (typeof web3 !== "undefined") {

          var network = await web3.eth.net.getId();
          var result = await web3.eth.getAccounts();

          var currAddr = result[0];
  
          var bnbBalance = await web3.eth.getBalance(currAddr);
          bnbBalance = bnbBalance / 10 ** 18
        
          console.log(network,config.NetworkId,'config.NetworkId')
          if (currAddr === undefined) 
          {
              currAddr = "";
          }
        
          if (network === config.NetworkId) {
              connect.network = network;
              connect.web3 = web3;
              connect.address = currAddr;
              connect.provider = provider;
              connect.isChange = "false";
              connect.connect = "yes";
            
          }
          else if (network !== config.NetworkId) {
              connect.web3 = "";
              connect.address = "";
              connect.network = 0;
              connect.provider = "";
              connect.isChange = "true";
          }
          else {
              return connect;
          }

      }
  }
  console.log(connect,'connect167')
  return connect;
}