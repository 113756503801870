import axios from "axios";
import config from "../config/config";


const baseUrl = config.baseUrl;
export const getHandbook = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-handbook`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        console.log( err.response.data.errors,'ererererererereeeeeeeeeeeeeeee')
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


