import { combineReducers } from "redux";

import web3Reucer from './webReducer';
import walletConnection from './walletConnection';
import language from './lang'

export default combineReducers({
    web3Reucer,
    walletConnection,
    language
});